import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Tooltip,
  Select,
  Checkbox,
  Tabs,
  Alert,
  InputNumber,
} from 'antd';
import { registerSupplierApi } from '../../../../../api/supplier';
import { getCompanyIdApi } from '../../../../../api/company';
import { getAccessTokenApi, logout } from '../../../../../api/auth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import { fetchApi } from '../../../../../helpers/fetch';
import {
  CERTIFICATIONS_CATALOG_ID,
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  STATE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  CANADA_VALUE_ID,
  PAYMENT_TERMS_CATALOG_ID,
  TIME_OF_MOVEMENTS_CATALOG_ID,
} from '../../../../../helpers/constants';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import TextArea from 'antd/lib/input/TextArea';

const AddSupllierForm = ({
  setModalIsVisible,
  setReloadSuppliers,
  supplierResponse,
  setReloadTable,
}) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const companyId = getCompanyIdApi();
  const [financiales, setFinanciales] = useState([]);
  const [pricings, setPricings] = useState([]);
  const token = getAccessTokenApi();
  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { TabPane } = Tabs;
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const [operationsCatalog, setOperationsCatalog] = useState([]);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [certificationsCatalog, setCertificationsCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  // const [packagingCatalog, setPackagingCatalog] = useState([]);
  // const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [timeOfMovements, setTimeOfMovements] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    getCatalogValueHandle(
      TIME_OF_MOVEMENTS_CATALOG_ID,
      setTimeOfMovements,
      setLoadingCatalog
    );
  }, []);
  useEffect(() => {
    if (loadingForm) {
      // const filters = `?role=${'Financial'}`;
      getUsersApi(token)
        .then((response) => {
          if (response) {
            let financials = [];
            let pricingsArray = [];
            response.result.forEach((element, index) => {
              if (element.roles.includes('Financial')) {
                financials.push(element);
              }
              if (element.roles.includes('Pricing')) {
                pricingsArray.push(element);
              }
            });
            let supp = financials.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            supp.sort((a, b) => a.label.localeCompare(b.label));
            setFinanciales(supp);
            let opp = pricingsArray.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            opp.sort((a, b) => a.label.localeCompare(b.label));
            setPricings(opp);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);
  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      PAYMENT_TERMS_CATALOG_ID,
      setPaymentTerms,
      setLoadingForm
    );
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      CERTIFICATIONS_CATALOG_ID,
      setCertificationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    // getCatalogValueHandle(
    //   PACKAGING_UNIT_CATALOG_ID,
    //   setPackagingCatalog,
    //   setLoadingForm
    // );
    // getCatalogValueHandle(
    //   MEASUREMENT_UNIT_CATALOG_ID,
    //   setMeasurementUnitCatalog,
    //   setLoadingForm
    // );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
  }, [token]);
  useEffect(() => {
    if (supplierResponse) {
      form.setFieldsValue({
        name: supplierResponse.supplierName,
        loadTypesArray: [supplierResponse.typeOfLoadId],
        operationsArray: [supplierResponse.operationId],
        typeOfServicesArray: [supplierResponse.typeOfServiceId],
        servicesArray: [supplierResponse.serviceId],
        unitsArray: [supplierResponse.equipmentTypeId],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierResponse]);
  const onChangeCountryOrigin = (value) => {
    if (value !== countrySelected) {
      form.setFieldsValue({
        state: '',
        city: '',
        address: '',
        zipCode: '',
      });
    }
    setCountrySelected(value);
    getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
  };
  const onChangeState = (value) => {
    getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    form.setFieldsValue({
      city: '',
      address: '',
      zipCode: '',
    });
  };

  const addSupplierFunction = async (values) => {
    setLoadingForm(true);
    let data = values;
    data['parentSupplierId'] = '';
    data['level'] = 0;
    data['company'] = companyId;

    if (supplierResponse) {
      data['routeId'] = supplierResponse.routeId;
      data['rfqLineId'] = supplierResponse.id;
    }
    const response = await registerSupplierApi(token, data);

    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelo más tarde',
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      setModalIsVisible(false);
      notification['success']({
        message: 'Proveedor agregado con éxito',
      });
      if (supplierResponse) {
        setReloadTable(true);
      } else {
        setReloadSuppliers(true);
      }
    } else {
      let messageError = 'Ocurrió un error, Inténtelo otra vez';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setLoadingForm(false);
  };
  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.name ||
      !values.supplierId ||
      !values.fiscalID ||
      !values.phoneNumber ||
      !values.financialId
    ) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete los campos obligatorios de detalle de proveedor'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
      return;
    }
    if (
      !values.OriginCountry ||
      !values.OriginZipCode ||
      !values.OriginState ||
      !values.OriginCity ||
      !values.OriginAddress ||
      !values.DestinationCountry ||
      !values.DestinationZipCode ||
      !values.DestinationState ||
      !values.DestinationCity ||
      !values.DestinationAddress
    ) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la sección "Dirección"');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    if (!values.servicesArray) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete el campo Servicio de la sección "Catálogos"'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };
  return (
    <Spin spinning={loadingForm || loadingCatalog} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={addSupplierFunction}
        initialValues={{ active: false }}
        onFinishFailed={errorFields}
      >
        <div>
          <Tabs defaultActiveKey="1" type="card" size={'middle'}>
            {/* TABPANE1 */}
            <TabPane tab="Detalle" key="1">
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Nombre de proveedor:"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un nombre',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Form.Item
                      label="Id.proveedor:"
                      name="supplierId"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un Id',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={5}>
                    <Form.Item
                      label="Tipo de proveedor:"
                      name="administrative"
                      valuePropName="checked"
                    >
                      <Checkbox>
                        Administrativo
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={4}>
                    <Form.Item
                      label="Proveedor estatus:"
                      name="active"
                      hidden
                      valuePropName="checked"
                    >
                      <Checkbox>Activo</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Número de proveedor:"
                      hidden
                      name="supplierNum"
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label="Correo electrónico:" name="emailAddress">
                      <Input
                        type={'email'}
                        placeholder="Ingresa tu información"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="RFC:"
                      name="fiscalID"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese RFC',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label="Nombre de contacto:" name="contactName">
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <span>
                          Teléfono:&nbsp;
                          <Tooltip title="El número telefónico debe contener 10 dígitos">
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresa número telefónico',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || value.toString().length === 10) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Por favor ingresa número telefónico a 10 dígitos'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item
                      label="Financiero:"
                      name="financialIds"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: 'Por favor seleccione un financiero',
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        mode="multiple"
                        allowClear
                        showArrow
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {financiales.map((f) => (
                          <Select.Option key={f.value} value={f.value}>
                            {f.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item label="Pricing:" name="pricingIds">
                      <Select
                        placeholder="Selecciona una opción"
                        mode="multiple"
                        allowClear
                        showArrow
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {pricings.map((f) => (
                          <Select.Option key={f.value} value={f.value}>
                            {f.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Movimientos esperados:"
                    name="expectedMovements"
                  >
                  <InputNumber placeholder="Número de movimientos" style={{width: "100%"}} />

                  </Form.Item>
                </Col>
                  <Col xs={24} md={12} lg={12}>
                    <Form.Item
                      label="Tiempo de movimientos:"
                      name="movementTimeId"
                    >
                      <Select
                          placeholder="Selecciona una opción"
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {timeOfMovements.map((s) => (
                            <Select.Option key={s.value} value={s.value}>
                              {s.label}
                            </Select.Option>
                          ))}
                        </Select>
                    </Form.Item>
                  </Col>
                </Row>
                  
                <Form.Item label="Observaciones:" name="observations">
                  <TextArea placeholder="Ingresa tu información" />
                </Form.Item>
              </div>
            </TabPane>
            {/* TABPANE2 */}
            <TabPane tab="Dirección" key="2" forceRender>
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="País"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese País',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        // disabled={disableAllInputs || service === 'land'}
                        placeholder="Selecciona una opción"
                        onChange={onChangeCountryOrigin}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {countries.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Estado:"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Estado',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeState}
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {states.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Ciudad:"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Ciudad',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {cities.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item
                      label="Dirección:"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Dirección',
                        },
                      ]}
                    >
                      <Input placeholder="Ingresa tu información" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="CP:"
                      name="zipCode"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un código postal',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              getFieldValue('originCountry') === CANADA_VALUE_ID
                            ) {
                              if (
                                value &&
                                value.toString().length >= 3 &&
                                value.toString().length <= 6
                              ) {
                                return Promise.resolve();
                              }
                            } else {
                              if (
                                value === undefined ||
                                value === '' ||
                                (value.toString().length === 5 && !isNaN(value))
                              ) {
                                return Promise.resolve();
                              }
                            }
                            return Promise.reject(
                              'Por favor ingrese un código postal válido'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        maxLength={5}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </TabPane>
            {/* TABPANE3 */}
            <TabPane tab="Catálogos" key="3" forceRender>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label="Operaciones:" name="operationsArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {operationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Certificaciones:"
                    name="certificationsArray"
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {certificationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label="Unidades:" name="unitsArray">
                    <Select
                      allowClear
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {unitTypeCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Tipo de carga:" name="loadTypesArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {typeOfLoadCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Servicios:"
                    name="servicesArray"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese un servicio',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {servicesCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Tipo servicio:" name="typeOfServicesArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {typeOfServiceCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col md={12} xs={24}>
                  <Form.Item label="HAZ MAT:" name="hazMatArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {hazMatCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Términos de pago:" name="paymentTermsId">
                    <Select
                      placeholder="Selecciona una opción"
                      showSearch
                      // mode="multiple"
                      allowClear
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {paymentTerms.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              
            </TabPane>
          </Tabs>
          {showAlert && (
            <Alert
              message={textType}
              description={textDescription}
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Row>
            <Col span={24}>
              <Form.Item>
                <Row justify="end">
                  <Button htmlType="submit" className="btn-enviar" block>
                    Guardar
                  </Button>
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  );
};

export default AddSupllierForm;
