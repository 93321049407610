import { Button, Col, Divider, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { PlusCircleOutlined, EyeOutlined } from '@ant-design/icons';
import TarifaImg from '../../../assets/img/png/tarifa.png';
import ProveedorImg from '../../../assets/img/png/proveedor.png';
import ClientImg from '../../../assets/img/png/client.png';
import CotizacionImg from '../../../assets/img/png/cotizacion.png';
import InvoiceImg from '../../../assets/img/png/invoice.png';
import OperationsImg from '../../../assets/img/png/operaciones.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddEditPricingForm from '../AddEditPricingForm/AddEditPricingForm';
import AddSupplierForm from '../../Admin/Administration/SuppliersTable/AddSupplierForm';
import AddClientForm from '../../Admin/Administration/ClientsTable/AddClientForm';
import Modal from '../../General/Modal';
import useUser from '../../../hooks/useUser';
import {
  ROL_ADMIN,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_PRICING,
  ROL_SUPPLIER,
  ROL_VENTAS,
} from '../../../utils/constants';
import UploadFileForm from '../../Admin/Payment/UploadFileForm';
import AddBillingForm from '../../Admin/Billling/AddBillingForm';
import SupplierTermsAndConditions from '../../Admin/Suppliers/SupplierTermsAndConditions/SupplierTermsAndConditions';

const CardsHome = () => {
  const { Title } = Typography;
  let history = useHistory();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const [reloadSuppliers, setReloadSuppliers] = useState(null);
  const [reloadClients, setReloadClients] = useState(null);
  const [reloadTable, setReloadTable] = useState(null);
  const { person } = useUser();
  

  const showModal = (
    type,
    billingFields,
    fileXml,
    filePdf,
    fileCfdi,
    nameXml
  ) => {
    if (type === 'AddTarifa') {
      setModalIsVisible(true);
      setTitleModal('Agregar tarifa');
      setContextModal(
        <AddEditPricingForm
          setModalIsVisible={setModalIsVisible}
          setReloadTable={setReloadTable}
        ></AddEditPricingForm>
      );
    } else if (type === 'AddSupplier') {
      setModalIsVisible(true);
      setTitleModal('Agregar proveedor');
      setContextModal(
        <AddSupplierForm
          setModalIsVisible={setModalIsVisible}
          setReloadSuppliers={setReloadSuppliers}
        ></AddSupplierForm>
      );
    } else if (type === 'AddClient') {
      setModalIsVisible(true);
      setTitleModal('Agregar cliente');
      setContextModal(
        <AddClientForm
          setModalIsVisible={setModalIsVisible}
          setReloadClients={setReloadClients}
        ></AddClientForm>
      );
    }
    if (type === 'addFile') {
      setModalIsVisible(true);
      setTitleModal('Facturación');
      setContextModal(
        <UploadFileForm
          setModalIsVisible={setModalIsVisible}
          showModal={showModal}
          type={type}
        />
      );
    } else if (type === 'addBillForm') {
      setModalIsVisible(true);
      setTitleModal('');
      setContextModal(
        <AddBillingForm
          setModalVisible={setModalIsVisible}
          billingFields={billingFields}
          fileXml={fileXml}
          filePdf={filePdf}
          fileCfdi={fileCfdi}
          nameXml={nameXml}
          // setReloadBills={setReloadBills}
        ></AddBillingForm>
      );
    }
    else if (type === 'addDocument') {
      setModalIsVisible(true);
      setTitleModal('');
      setContextModal(
       <SupplierTermsAndConditions 
       setModalVisible={setModalIsVisible}
       supplierId={person.supplierId}
       ></SupplierTermsAndConditions>
      );
    }
  };
  return (
    <div>
      <Row gutter={16}>
        {[ROL_LAYOUT, ROL_MANAGER, ROL_PRICING].some((r) =>
          person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={TarifaImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Tarifa
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Row justify="end">
                <Col style={{ justifyContent: 'flex-end' }}>
                  {[ROL_LAYOUT, ROL_PRICING].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => showModal('AddTarifa')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                  {[ROL_MANAGER].some((r) => person.roles.includes(r)) && (
                    <Button
                      onClick={() => history.push(`/pricing/tarifa`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Ver
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={CotizacionImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Contrato
                </Title>
                <Divider />
              </Col>
              <Col style={{ justifyContent: 'flex-end' }}>
                <Button
                onClick={() => showModal('addDocument')}
                  className="btn-download"
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={TarifaImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Ver facturas
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Col style={{ justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => history.push(`/facturacion`)}
                  className="btn-download"
                  icon={<EyeOutlined />}
                  style={{ width: 120 }}
                >
                  Ver
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_SUPPLIER].some((r) => person.roles.includes(r)) && person.administrativeSupplier === false && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={InvoiceImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Facturar
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Col style={{ justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => history.push(`/proveedor/operaciones`)}
                  className="btn-download"
                  icon={<PlusCircleOutlined />}
                  style={{ width: 120 }}
                >
                  Agregar
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_FINANCIAL].some((r) => person.roles.includes(r)) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={InvoiceImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Factura o nota de crédito
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => history.push(`/cxp/facturacion/proveedores`)}
                  className="btn-download"
                  icon={<EyeOutlined />}
                  style={{ width: 120 }}
                >
                  Ver
                </Button>
              </Col>
            </div>
          </Col>
        )}
        {[ROL_LAYOUT, ROL_MANAGER, ROL_VENTAS, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={CotizacionImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Cotización
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Row justify="end">
                <Col style={{ justifyContent: 'flex-end' }}>
                  {[ROL_LAYOUT, ROL_VENTAS].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/cotizacion/nueva`)}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                  {[ROL_MANAGER, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/cotizacion`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Ver
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {[ROL_LAYOUT, ROL_MANAGER, ROL_ADMIN, ROL_PRICING, ROL_FINANCIAL].some(
          (r) => person.roles.includes(r)
        ) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={ProveedorImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Proveedor
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Row justify="end">
                <Col style={{ justifyContent: 'flex-end' }}>
                  {[ROL_LAYOUT, ROL_ADMIN, ROL_PRICING].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => showModal('AddSupplier')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                  {[ROL_MANAGER, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/proveedores`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Ver
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {[
          ROL_ADMIN,
          ROL_VENTAS,
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          ROL_LAYOUT,
        ].some((r) => person.roles.includes(r)) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={ClientImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Cliente
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Row justify="end">
                <Col style={{ justifyContent: 'flex-end' }}>
                  {[ROL_ADMIN, ROL_VENTAS, ROL_LAYOUT].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => showModal('AddClient')}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                  {[ROL_MANAGER, ROL_OPERACIONES, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/clientes`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Ver
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
        {[
          ROL_OPERACIONES,
          ROL_MANAGER,
          ROL_VENTAS,
          ROL_FINANCIAL,
          ROL_LAYOUT,
        ].some((r) => person.roles.includes(r)) && (
          <Col md={8} xs={24}>
            <div className="admin__container">
              <Col>
                <img width={'100%'} src={OperationsImg} />
                <Title
                  style={{
                    color: 'black',
                    marginTop: 10,
                    textAlign: 'center',
                  }}
                  level={5}
                >
                  Operación
                </Title>
                {/* <p style={{ textAlign: 'center' }}>Descripción</p> */}
                <Divider />
              </Col>
              <Row justify="end">
                <Col style={{ justifyContent: 'flex-end' }}>
                  {[ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/operacion/nueva`)}
                      className="btn-download"
                      icon={<PlusCircleOutlined />}
                      style={{ width: 120 }}
                    >
                      Agregar
                    </Button>
                  )}
                  {[ROL_MANAGER, ROL_VENTAS, ROL_FINANCIAL].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      onClick={() => history.push(`/operaciones`)}
                      className="btn-download"
                      icon={<EyeOutlined />}
                      style={{ width: 120 }}
                    >
                      Ver
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        )}
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </div>
  );
};

export default CardsHome;
