import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Spin,
  Form,
  Input,
  Button,
  notification,
  Modal as ModalAntd,
  Tooltip,
  Checkbox,
  Select,
  Tabs,
  Alert,
  InputNumber,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getSupplierApi, editSupplierApi } from '../../../../../api/supplier';
import { logout } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getUsersApi } from '../../../../../api/users';
import { METHOD_GET } from '../../../../../utils/constants';
import { API_URL_CATALOG_VALUES } from '../../../../../helpers/urls';
import {
  CERTIFICATIONS_CATALOG_ID,
  CITY_CATALOG_ID,
  COUNTRY_CATALOG_ID,
  OPERACIONES_CATALOG_ID,
  STATE_CATALOG_ID,
  UNIT_TYPE_CATALOG_ID,
  TYPE_OF_LOAD_CATALOG_ID,
  SERVICES_CATALOG_ID,
  HAZMAT_CATALOG_ID,
  TYPE_OF_SERVICE_CATALOG_ID,
  PAYMENT_TERMS_CATALOG_ID,
  CANADA_VALUE_ID,
  TIME_OF_MOVEMENTS_CATALOG_ID,
} from '../../../../../helpers/constants';
import { fetchApi } from '../../../../../helpers/fetch';
import { getCatalogValueHandle } from '../../../../../helpers/handlerFunctionApi';
import TextArea from 'antd/lib/input/TextArea';

const EditSupplierForm = ({
  setModalIsVisible,
  setReloadSuppliers,
  idSupplier,
  type,
}) => {
  const [loadingForm, setLoadingForm] = useState(true);
  const [form] = Form.useForm();
  const [editUserForm, setEditUserForm] = useState([]);
  const companyId = getCompanyIdApi();
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const [financiales, setFinanciales] = useState([]);
  const [pricings, setPricings] = useState([]);

  const [countrySelected, setCountrySelected] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { TabPane } = Tabs;
  const [textType, setTextType] = useState('');
  const [textDescription, setTextDescription] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [disableAllInputs, setDisableAllInputs] = useState(
    type === 'watch' ? true : false
  );

  const [operationsCatalog, setOperationsCatalog] = useState([]);
  const [unitTypeCatalog, setUnitTypeCatalog] = useState([]);
  const [certificationsCatalog, setCertificationsCatalog] = useState([]);
  const [hazMatCatalog, setHazMatsCatalog] = useState([]);
  const [typeOfServiceCatalog, setTypeOfServiceCatalog] = useState([]);
  // const [packagingCatalog, setPackagingCatalog] = useState([]);
  // const [measurementUnitCatalog, setMeasurementUnitCatalog] = useState([]);
  const [servicesCatalog, setServicesCatalog] = useState([]);
  const [typeOfLoadCatalog, setTypeOfLoadCatalog] = useState([]);
  const [paymentTerms, setPaymentTerms] = useState([]);
  const [hasRoutes, setHasRoutes] = useState(false);
  const [timeOfMovements, setTimeOfMovements] = useState([]);
  const [loadingCatalog, setLoadingCatalog] = useState();

  useEffect(() => {
    getCatalogValueHandle(
      TIME_OF_MOVEMENTS_CATALOG_ID,
      setTimeOfMovements,
      setLoadingCatalog
    );
  }, []);
  useEffect(() => {
    if (loadingForm) {
      // const filters = `?role=${'Financial'}`;
      getUsersApi(token)
        .then((response) => {
          if (response) {
            let financials = [];
            let pricingsArray = [];
            response.result.forEach((element, index) => {
              if (element.roles.includes('Financial')) {
                financials.push(element);
              }
              if (element.roles.includes('Pricing')) {
                pricingsArray.push(element);
              }
            });
            let supp = financials.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            supp.sort((a, b) => a.label.localeCompare(b.label));
            setFinanciales(supp);
            let opp = pricingsArray.map((f) => ({
              value: f.id,
              label: f.userName,
            }));
            opp.sort((a, b) => a.label.localeCompare(b.label));
            setPricings(opp);
          }
          setLoadingForm(false);
        })
        .catch((err) => {});
    }
  }, [token, loadingForm]);

  useEffect(() => {
    getSupplierApi(token, idSupplier).then((response) => {
      setEditUserForm([
        {
          name: ['name'],
          value: response.result.name,
        },
        {
          name: ['supplierNum'],
          value: response.result.supplierNum,
        },
        {
          name: ['supplierId'],
          value: response.result.supplierId,
        },
        {
          name: ['active'],
          value: response.result.active,
        },
        {
          name: ['address'],
          value: response.result.address,
        },
        {
          name: ['city'],
          value: response.result.city !== 0 ? response.result.city : null,
        },
        {
          name: ['state'],
          value: response.result.state !== 0 ? response.result.state : null,
        },
        {
          name: ['zipCode'],
          value: response.result.zipCode,
        },
        {
          name: ['country'],
          value: response.result.country !== 0 ? response.result.country : null,
        },
        {
          name: ['emailAddress'],
          value: response.result.emailAddress,
        },
        {
          name: ['contactName'],
          value: response.result.contactName,
        },
        {
          name: ['fiscalID'],
          value: response.result.fiscalID,
        },
        {
          name: ['phoneNumber'],
          value: response.result.phoneNumber,
        },
        {
          name: ['financialIds'],
          value: response.result.financialIds,
        },
        {
          name: ['pricingIds'],
          value: response.result.pricingIds,
        },
        {
          name: ['operationsArray'],
          value: response.result.operationsArray,
        },
        {
          name: ['unitsArray'],
          value: response.result.unitsArray,
        },
        {
          name: ['certificationsArray'],
          value: response.result.certificationsArray,
        },
        {
          name: ['loadTypesArray'],
          value: response.result.loadTypesArray,
        },
        {
          name: ['typeOfServicesArray'],
          value: response.result.typeOfServicesArray,
        },
        {
          name: ['servicesArray'],
          value: response.result.servicesArray,
        },
        {
          name: ['measurementUnitsArray'],
          value: response.result.measurementUnitsArray,
        },
        {
          name: ['packagingArray'],
          value: response.result.packagingArray,
        },
        {
          name: ['hazMatArray'],
          value: response.result.hazMatArray,
        },
        {
          name: ['observations'],
          value: response.result.observations,
        },
        {
          name: ['paymentTermsId'],
          value: response.result.paymentTermsId,
        },
        {
          name: ['expectedMovements'],
          value: response.result.expectedMovements,
        },
        {
          name: ['createdByName'],
          value: response.result.createdByName,
        },
        {
          name: ['movementTimeId'],
          value: response.result.movementTimeId,
        },
        {
          name: ['administrative'],
          value: response.result.administrative,
        },
      ]);
      setHasRoutes(response.result.hasRoutes);
    });
    // return () => {
    //     cleanup
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idSupplier]);

  useEffect(() => {
    setLoadingForm(true);
    const config = {
      method: METHOD_GET,
      url: `${API_URL_CATALOG_VALUES}/GetAll/${COUNTRY_CATALOG_ID}`,
      data: null,
      token,
      // showNotification: true
    };
    fetchApi(config)
      .then((response) => {
        if (response) {
          let calogsValues = response.result;

          if (calogsValues) {
            calogsValues.map((cat, index) => {
              cat['key'] = index;
              cat['label'] = cat.description;
              cat['value'] = cat.id;
            });
          }
          setCountries(calogsValues);
        }
        setLoadingForm(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getCatalogValueHandle(
      PAYMENT_TERMS_CATALOG_ID,
      setPaymentTerms,
      setLoadingForm
    );
    getCatalogValueHandle(
      STATE_CATALOG_ID,
      setStates,
      setLoadingForm,
      form.getFieldValue('country')
    );
    getCatalogValueHandle(
      CITY_CATALOG_ID,
      setCities,
      setLoadingForm,
      form.getFieldValue('state')
    );
    getCatalogValueHandle(
      OPERACIONES_CATALOG_ID,
      setOperationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      UNIT_TYPE_CATALOG_ID,
      setUnitTypeCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      CERTIFICATIONS_CATALOG_ID,
      setCertificationsCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(HAZMAT_CATALOG_ID, setHazMatsCatalog, setLoadingForm);
    getCatalogValueHandle(
      TYPE_OF_SERVICE_CATALOG_ID,
      setTypeOfServiceCatalog,
      setLoadingForm
    );
    // getCatalogValueHandle(
    //   PACKAGING_UNIT_CATALOG_ID,
    //   setPackagingCatalog,
    //   setLoadingForm
    // );
    // getCatalogValueHandle(
    //   MEASUREMENT_UNIT_CATALOG_ID,
    //   setMeasurementUnitCatalog,
    //   setLoadingForm
    // );
    getCatalogValueHandle(
      SERVICES_CATALOG_ID,
      setServicesCatalog,
      setLoadingForm
    );
    getCatalogValueHandle(
      TYPE_OF_LOAD_CATALOG_ID,
      setTypeOfLoadCatalog,
      setLoadingForm
    );
    //   getStateCatalogHandle();
  }, [form, token, editUserForm]);

  const onChangeCountryOrigin = React.useCallback(
    async (value) => {
      if (value !== countrySelected) {
        form.setFieldsValue({
          state: null,
          city: null,
          address: null,
          zipCode: null,
        });
      }
      setCountrySelected(value);
      getCatalogValueHandle(STATE_CATALOG_ID, setStates, setLoadingForm, value);
      getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
    },
    [form, countrySelected]
  );

  const onChangeState = React.useCallback(
    async (value) => {
      getCatalogValueHandle(CITY_CATALOG_ID, setCities, setLoadingForm, value);
      form.setFieldsValue({
        city: null,
        address: null,
        zipCode: null,
      });
    },
    [form]
  );

  const handleEditSupplier = (values) => {
    confirm({
      title: 'Editar proveedor',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas guardar datos modificados?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        editSupplierFunction(values);
      },
      onCancel() {},
    });
  };

  const editSupplierFunction = async (values) => {
    setLoadingForm(true);
    // let arrayPaymentTerms = values.paymentTermsArray?.map((e) => ({
    //   CatalogValueId: e,
    // }));
    let data = values;
    data['parentSupplierId'] = '';
    data['level'] = 0;
    data['company'] = companyId;
    // data['supplierPaymentTerms'] = arrayPaymentTerms;
    let response = null;
    try {
      response = await editSupplierApi(token, data, idSupplier);
    } catch (error) {
    } finally {
      setLoadingForm(false);
    }
    if (response === undefined) {
      notification['error']({
        message: 'Ocurrió un error, Inténtelo más tarde',
      });
      return false;
    }
    if (response.statusCode === 401) {
      notification['error']({
        message: 'Usuario no autorizado.',
      });
      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1500);
      return false;
    }
    if (response.statusCode === 200) {
      notification['success']({
        message: 'Proveedor editado con éxito',
      });
      setModalIsVisible(false);
    } else {
      let messageError = 'Ocurrió un error, Inténtelo otra vez';
      if (response.statusCode === 409) {
        messageError = response.description;
      }
      if (response.statusCode === 400) {
        messageError = response.description;
      }
      notification['error']({
        message: messageError,
      });
    }
    setReloadSuppliers(true);

    setLoadingForm(false);
  };
  const errorFields = ({ values, errorFields, outOfDate }) => {
    if (
      !values.name ||
      !values.supplierId ||
      !values.fiscalID ||
      !values.phoneNumber ||
      !values.financialId
    ) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete los campos obligatorios de detalle de proveedor'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 10000);
      return;
    }
    if (
      !values.OriginCountry ||
      !values.OriginZipCode ||
      !values.OriginState ||
      !values.OriginCity ||
      !values.OriginAddress ||
      !values.DestinationCountry ||
      !values.DestinationZipCode ||
      !values.DestinationState ||
      !values.DestinationCity ||
      !values.DestinationAddress
    ) {
      setTextType('Datos incompletos');
      setTextDescription('Complete todos los datos de la sección "Dirección"');
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
    if (!values.service) {
      setTextType('Datos incompletos');
      setTextDescription(
        'Complete el campo Servicio de la sección "Catálogos"'
      );
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    }
  };

  return (
    <Spin spinning={loadingForm || loadingCatalog} tip="Cargando...">
      <Form
        name="add-promo"
        layout="vertical"
        form={form}
        onFinish={handleEditSupplier}
        fields={editUserForm}
        // onFieldsChange={(changedFields, allFields) => {
        //   setEditUserForm(allFields);
        // }}
        onFinishFailed={errorFields}
      >
        <div>
          <Tabs defaultActiveKey="1" type="card" size={'middle'}>
            <TabPane tab="Detalle" key="1">
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Nombre de proveedor:"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un nombre',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col xs={24} md={12}>
                    <Form.Item label="Número de proveedor:" name="supplierNum">
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} md={5}>
                    <Form.Item
                      // label="Proveedor estatus:"
                      label={
                        <span>
                          Proveedor estatus:&nbsp;
                          <Tooltip title="Es necesario tener rutas para activar">
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      name="active"
                      valuePropName="checked"
                    >
                      <Checkbox disabled={disableAllInputs || !hasRoutes}>
                        Activo
                      </Checkbox>
                    </Form.Item>

                    <Form.Item label="Estatus:" name="active" hidden>
                      <Checkbox checked disabled={disableAllInputs} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={5}>
                    <Form.Item
                      // label="Proveedor estatus:"
                      label="Tipo de proveedor:"
                      name="administrative"
                      valuePropName="checked"
                    >
                      <Checkbox disabled={disableAllInputs}>
                        Administrativo
                      </Checkbox>
                    </Form.Item>
                  </Col>
                 
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Id.proveedor:"
                      name="supplierId"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un Id',
                        },
                      ]}
                      hidden
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label="Correo electrónico:" name="emailAddress">
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="RFC:"
                      name="fiscalID"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese RFC',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item label="Nombre de contacto:" name="contactName">
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label={
                        <span>
                          Teléfono:&nbsp;
                          <Tooltip title="El número telefónico debe contener 10 dígitos">
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </span>
                      }
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresa número telefónico',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || value.toString().length === 10) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              'Por favor ingresa número telefónico a 10 dígitos'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  {financiales && (
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Financiero:"
                        name="financialIds"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: 'Por favor seleccione un financiero',
                        //   },
                        // ]}
                      >
                        <Select
                          placeholder="Selecciona una opción"
                          mode="multiple"
                          allowClear
                          showArrow
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={disableAllInputs}
                        >
                          {financiales.map((f) => (
                            <Select.Option key={f.value} value={f.value}>
                              {f.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {pricings && (
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item label="Pricing:" name="pricingIds">
                        <Select
                          placeholder="Selecciona una opción"
                          mode="multiple"
                          allowClear
                          showArrow
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={disableAllInputs}
                        >
                          {pricings.map((f) => (
                            <Select.Option key={f.value} value={f.value}>
                              {f.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                <Row gutter={16}>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Movimientos esperados:"
                    name="expectedMovements"
                  >
                  <InputNumber disabled={disableAllInputs} placeholder="Número de movimientos" style={{width: "100%"}} />

                  </Form.Item>
                </Col>
                <Col xs={24} md={12} lg={12}>
                  <Form.Item
                    label="Tiempo de movimientos:"
                    name="movementTimeId"
                  >
                    <Select
                        disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {timeOfMovements.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                  </Form.Item>
                </Col>
                </Row>
               
                <Form.Item label="Observaciones:" name="observations">
                  <TextArea
                    placeholder="Ingresa tu información"
                    disabled={disableAllInputs}
                  />
                </Form.Item>
                <Row justify={'end'}>
                <Col xs={24} md={7}>
                  <Form.Item
                      label="Creado por:"
                      name="createdByName"
                    >
                      <Input
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tab="Dirección" key="2">
              <div>
                <Row gutter={16}>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="País:"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese País',
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecciona una opción"
                        disabled={disableAllInputs}
                        onChange={onChangeCountryOrigin}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {countries.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Estado:"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Estado',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        disabled={disableAllInputs}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onChangeState}
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {states.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="Ciudad:"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Ciudad',
                        },
                      ]}
                    >
                      <Select
                        // disabled={disableAllInputs}
                        placeholder="Selecciona una opción"
                        disabled={disableAllInputs}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // style={{minWidth: width > 480 ? 220 : '100%', width: width > 480 ? 220 : '100%'}}
                      >
                        {cities.map((s) => (
                          <Select.Option key={s.value} value={s.value}>
                            {s.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Form.Item
                      label="Dirección:"
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese Dirección',
                        },
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item
                      label="CP:"
                      name="zipCode"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingrese un código postal',
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              getFieldValue('originCountry') === CANADA_VALUE_ID
                            ) {
                              if (
                                value &&
                                value.toString().length >= 3 &&
                                value.toString().length <= 6
                              ) {
                                return Promise.resolve();
                              }
                            } else {
                              if (
                                value === undefined ||
                                value === '' ||
                                (value.toString().length === 5 && !isNaN(value))
                              ) {
                                return Promise.resolve();
                              }
                            }
                            return Promise.reject(
                              'Por favor ingrese un código postal válido'
                            );
                          },
                        }),
                      ]}
                    >
                      <Input
                        placeholder="Ingresa tu información"
                        maxLength={5}
                        disabled={disableAllInputs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tab="Catálogos" key="3" forceRender>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label="Operaciones:" name="operationsArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {operationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Certificaciones:"
                    name="certificationsArray"
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {certificationsCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item label="Unidades:" name="unitsArray">
                    <Select
                      allowClear
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {unitTypeCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Tipo de carga:" name="loadTypesArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {typeOfLoadCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Servicios:"
                    name="servicesArray"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor ingrese un servicio',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {servicesCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Tipo servicio:" name="typeOfServicesArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {typeOfServiceCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
              <Col md={12} xs={24}>
                  <Form.Item label="HAZ MAT:" name="hazMatArray">
                    <Select
                      placeholder="Selecciona una opción"
                      mode="multiple"
                      allowClear
                      showArrow
                      disabled={disableAllInputs}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {hazMatCatalog.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item label="Términos de pago:" name="paymentTermsId">
                    <Select
                      placeholder="Selecciona una opción"
                      showSearch
                      // mode="multiple"
                      allowClear
                      disabled={disableAllInputs}
                      showArrow
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {paymentTerms.map((s) => (
                        <Select.Option key={s.value} value={s.value}>
                          {s.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
          {showAlert && (
            <Alert
              message={textType}
              description={textDescription}
              type="warning"
              showIcon
              style={{ marginBottom: 20 }}
            />
          )}
          <Col span={24}>
            <Form.Item>
              <Row justify="end">
                <Button
                  htmlType="submit"
                  className="btn-enviar"
                  disabled={disableAllInputs}
                  block
                >
                  Guardar
                </Button>
              </Row>
            </Form.Item>
          </Col>
        </div>
      </Form>
    </Spin>
  );
};

export default EditSupplierForm;
