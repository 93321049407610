import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Input,
  Space,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
  EyeOutlined,
  DollarCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { formatMoney } from '../../../utils/general';
import Highlighter from 'react-highlight-words';
import Modal from '../../General/Modal';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  METHOD_DELETE,
  METHOD_GET,
  ROL_FINANCIAL,
  ROL_LAYOUT,
  ROL_SUPPLIER,
} from '../../../utils/constants';
import useUser from '../../../hooks/useUser';
import useStatusFilter from '../../../hooks/useStatusFilter';
import { fetchApi } from '../../../helpers/fetch';
import { getAccessTokenApi } from '../../../api/auth';
import BillingPurchaseOrders from '../../Admin/Billling/BillingPurchaseOrders';
import PaymentPurchaseOrder from '../../Logistic/PurchaseOrderTable/PaymentPurchaseOrder';
import AddPurchaseOrder from '../../Admin/Billling/AddPurchaseOrder/AddPurchaseOrder';
import confirm from 'antd/lib/modal/confirm';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../helpers/saveFilterData';

export default function PurchaseOrderTable() {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [loadinTable, setLoadinTable] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  const { person } = useUser();
  const [billsExport, setbillsExport] = useState([]);
  const { statusFilter, reset } = useStatusFilter();
  const token = getAccessTokenApi();
  const [startDate, setStartDate] = useState({
    paymentDate: '',
  });
  const [endDate, setEndDate] = useState({
    paymentDate: '',
  });
  const x = React.createRef();
  const [reloadTable, setReloadTable] = useState(false);

  useEffect(() => {
    setLoadinTable(true);
    let config = {
      method: METHOD_GET,
      url: [ROL_SUPPLIER].some((r) =>
      person.roles.includes(r)) ? `SalesOrder?supplierId=${person.supplierId}` : `SalesOrder`,
      token,
      showNotification: false,
    };
    fetchApi(config)
      .then((response) => {
        response.result.forEach((element, index) => {
          element['key'] = index;
        });
        setPurchaseOrders(response.result);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadinTable(false);
        setReloadTable(false);
      });
  }, [reloadTable, token]);
  useEffect(() => {
    setbillsExport(purchaseOrders);
  }, [purchaseOrders]);
  useEffect(() => {
    if (statusFilter.type === '') {
      setFilteredInfo({});
    }
  }, [statusFilter]);
  useEffect(() => {
    //filtar dependiendo el estatus de los amount
    const statusReceive = statusFilter.type;
    if (statusReceive !== '') {
      // console.log(statusFilter);
      // const billsFilters = purchaseOrders.filter(
      //     (bill) => bill.status === statusReceive
      // );
      let billsFilters;
      if (statusReceive === 'Total') {
        billsFilters = purchaseOrders.filter(
          (bill) =>
            bill.invoiceStatus === 'Vigente' || bill.invoiceStatus === 'Vencido'
        );
      } else {
        billsFilters = purchaseOrders.filter(
          (bill) => bill.invoiceStatus === statusReceive
        );
      }

      const filterEntrance = {
        documentType: null,
        invoiceSerieType: null,
        invoiceNum: null,
        currencyId: null,
        hasOpenNote: null,
        status:
          statusReceive === 'Total' ? ['Vigente', 'Vencido'] : [statusReceive],
      };
      const pagination = {
        current: 1,
        pageSize: 10,
      };

      const extra = {
        currentDataSource: billsFilters,
        action: 'filter',
      };

      handleChange(pagination, filterEntrance, {}, extra);
      setTimeout(() => {
        reset();
      }, 3000);
      // setFisrtTime(false);
    }
  }, []);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('purchaseOrder');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                purchaseOrders
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                purchaseOrders
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  purchaseOrders
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text !== 0 && text !== null ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : null,
  });
  const columns = [
    {
      title: 'Id. orden de compra',
      dataIndex: 'id',
      key: 'id',
      width: 110,

      ...getColumnSearchProps('id'),
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: 200,
      ellipsis: true,
      ...getColumnSearchProps('supplierName'),
    },
    {
      title: 'Creado por',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      ...getColumnSearchProps('createdBy'),
      width: 120,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      width: 150,

      render: (total) => `$${formatMoney(total)}`,
    },
    {
      title: 'Moneda',
      dataIndex: 'currency',
      key: 'currency',
      align: 'center',
      width: 150,
      ellipsis: true,
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currency || null,
      onFilter: (value, record) => record.currency.includes(value),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        { text: 'Vigente', value: 'VIGENTE' },
        { text: 'Pagada', value: 'PAGADA' },
        { text: 'Vencido', value: 'VENCIDO' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'VIGENTE'
              ? 'orange'
              : status === 'PAGADA'
              ? 'green'
              : 'red'
          }
        >
          {status?.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Fecha de pago',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      align: 'center',
      width: 100,
      render: (paymentDate) => (
        <>
          {paymentDate !== null
            ? moment.unix(paymentDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('paymentDate'),
    },
    {
      title: 'Acciones',
      dataIndex: 'description',
      key: '3',
      align: 'center',
      width: 180,
      render: (statusText, record) => (
        <>
        {![ROL_SUPPLIER].some((r) =>
      person.roles.includes(r)) && (
<Tooltip title="Editar">
            <Button
              style={{ marginLeft: 3, marginRight: 5 }}
              type="primary"
              disabled={
                record.status === 'PAGADA' ||
                ![ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                )
              }
              size="medium"
              shape="default"
              icon={<EditOutlined />}
              onClick={() => showModal('edit', record)}
            />
          </Tooltip>
      )}
          <Tooltip title="Ver facturas">
            <Button
              style={{ marginLeft: 3, marginRight: 5 }}
              type="primary"
              ghost
              size="medium"
              shape="default"
              icon={<EyeOutlined />}
              onClick={() => showModal('bills', record)}
            />
          </Tooltip>
          {[ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
            person.roles.includes(r)
          ) && (
            <Tooltip title="Pagar orden de compra">
              <Button
                type="primary"
                ghost
                shape="default"
                size="medium"
                style={{
                  background: ` ${
                    record.documentType !== 'E' ? '#f6ffed' : '#e6e3e3'
                  } `,
                  color: ` ${
                    record.documentType !== 'E' ? '#389e0d' : '#a1a1a1'
                  } `,
                  borderColor: ` ${
                    record.documentType !== 'E' ? '#b7eb8f' : '#a1a1a1'
                  } `,
                  marginLeft: 3,
                  marginRight: 5,
                }}
                // disabled={record.invoiceStatus}
                icon={<DollarCircleOutlined />}
                onClick={() => showModal('payment', record)}
              />
            </Tooltip>
          )}
          {![ROL_SUPPLIER].some((r) =>
      person.roles.includes(r)) && (
          <Tooltip title="Eliminar">
            <Button
              danger
              size="medium"
              type="primary"
              icon={<DeleteOutlined />}
              disabled={
                record.status === 'PAGADA' ||
                ![ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                )
              }
              shape="default"
              onClick={() => handleDeleteSo(record.id)}
            ></Button>
          </Tooltip>
      )}
        </>
      ),
    },
  ];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('purchaseOrder', pagination, sorter, filters,dateFilters);
    setFilteredInfo(filters);
    setbillsExport(extra.currentDataSource);
  };

  const showModal = (type, record) => {
    setModalIsVisible(true);
    if (type === 'bills') {
      setTitleModal(`Facturas - orden de compra #${record.id}`);
      setContextModal(
        <BillingPurchaseOrders
          invoices={record.invoices}
          supplierName={record.supplierName}
        ></BillingPurchaseOrders>
      );
    } else if (type === 'payment') {
      setModalIsVisible(true);
      setTitleModal(`Pago orden de compra #${record.id}`);
      setContextModal(
        <PaymentPurchaseOrder
          setModalIsVisible={setModalIsVisible}
          poId={record.id}
          setReloadTable={setReloadTable}
          type={record.paymentDate ? 'watch' : 'add'}
          response={record}
        ></PaymentPurchaseOrder>
      );
    } else if (type === 'edit') {
      record.invoices.forEach((element, index) => {
        element['key'] = index;
      });
      setModalIsVisible(true);
      setTitleModal(`Pago orden de compra #${record.id}`);
      setContextModal(
        <AddPurchaseOrder
          setModalIsVisible={setModalIsVisible}
          purchaseOrders={record.invoices}
          idPurchaseOrder={record.id}
          line={record}
          setReloadTable={setReloadTable}
        ></AddPurchaseOrder>
      );
    }
  };
  const generateExcel = (entries) => {
    const filename = `Ordenes-de-compra${moment().format('L')}.xlsx`;
    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;
    var mstrTitulo = `Ordenes de compra`;
    ws = {
      A1: { v: mstrTitulo, t: 's' },
      I1: { v: fecha, t: 's' },

      A3: { v: 'Id. orden de compra', t: 's' },
      B3: { v: 'Proveedor', t: 's' },
      C3: { v: 'Creado por', t: 's' },
      D3: { v: 'Total', t: 's' },
      E3: { v: 'Estatus', t: 's' },
      F3: { v: 'Fecha de pago', t: 's' },
    };
    for (let i in entries) {
      const { id, supplierName, createdBy, total, status, paymentDate } =
        entries[i];
      ws['A' + renInicial] = { v: id, t: 's' };
      ws['B' + renInicial] = { v: supplierName, t: 's' };
      ws['C' + renInicial] = { v: createdBy, t: 's' };
      ws['D' + renInicial] = {
        v: `$${formatMoney(total)}`,
        t: 's',
      };
      ws['E' + renInicial] = { v: status, t: 's' };
      ws['F' + renInicial] = {
        v: paymentDate ? moment.unix(paymentDate).format('DD/MM/YYYY') : '',
        t: 's',
      };
      renInicial++;
    }
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
  };
  const exportPdf = (purchaseOrders, startDate, endDate) => {
    var mstrTitulo = `Ordenes de compra`;

    var mstrPdf = `Ordenes-de-compra${moment().format('L')}.pdf`;

    var registros = [];
    purchaseOrders.forEach((element) => {
      registros.push([
        element.id,
        element.supplierName,
        element.createdBy,
        '$ ' + formatMoney(element.total),
        element.status,
        element.paymentDate
          ? moment.unix(element.paymentDate).format('DD/MM/YYYY')
          : '',
      ]);
    });

    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      let str = 'Página ' + data.pageCount;
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };
    doc.autoTable({
      head: [
        [
          'Id. orden de compra',
          'Proveedor',
          'Creado por',
          'Total',
          'Estatus',
          'Fecha de pago',
        ],
      ],
      didDrawPage: pageContent,
      theme: 'grid',
      headStyles: {
        valign: 'middle',
        halign: 'center',
        fillColor: [230, 81, 0],
        textColor: [255, 255, 255],
      },
      body: registros,
      columnStyles: {
        text: {
          cellWidth: 'wrap',
        },
      },
    });
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(mstrPdf);
  };
  const handleDeleteSo = (id) => {
    confirm({
      title: 'Eliminar orden de compra',
      icon: <ExclamationCircleOutlined />,
      content:
        '¿Estás seguro que deseas eliminar la orden de compra seleccionada?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteSo(id);
      },
      onCancel() {},
    });
  };

  const deleteSo = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `SalesOrder/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadTable(true);
      }
    }
  };
  return (
    <>
      <Table
        onChange={handleChange}
        columns={columns}
        dataSource={purchaseOrders}
        scroll={{ x: 1000 }}
        loading={loadinTable}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(billsExport)}
            disabled={billsExport?.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(billsExport)}
            disabled={billsExport?.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
