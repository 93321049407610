import {
  Button,
  Col,
  Form,
  Modal as ModalAntd,
  Row,
  Spin,
  Upload,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getAccessTokenApi } from '../../../../api/auth';
import {
  METHOD_GET,
  METHOD_POST,
  ROL_FINANCIAL,
  ROL_ADMIN,
  ROL_LAYOUT,
  ROL_SUPPLIER,
  METHOD_DELETE,
} from '../../../../utils/constants';
import { fetchApi, fetchBlob } from '../../../../helpers/fetch';
import moment from 'moment';
import { API_URL_PAYMENTS } from '../../../../helpers/urls';
import {
  ExclamationCircleOutlined,
  FileAddOutlined,
  UploadOutlined,
} from '@ant-design/icons';
// import AddDocumentsPagoForm from '../Documents/AddDocumentsPagoForm';
import toBase64 from '../../../../utils/toBase64';
import useUser from '../../../../hooks/useUser';

const PaymentComplement = (props) => {
  const [loadingImage, setLoadingImage] = useState(false);
  const [paymentComplementFile, setPaymentComplementFile] = useState([]);
  const { person } = useUser();
  const {
    setModalIsVisible,
    invoiceId,
    paymentId,
    setReloadPayments,
    type,
    typeInvoice,
  } = props;
  const [invoicePaymentForm] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();
  const { confirm } = ModalAntd;
  const extraImagesChange = (info) => {
    let fileList = [...info.fileList];
    if (fileList.length > 1) {
      notification['warn']({
        message: 'No puede subir más de 1 documento',
      });
    }
    setPaymentComplementFile(fileList);
  };

  const propsUpload = {
    onRemove: async (file) => {
      const config = {
        method: METHOD_DELETE,
        url: `Payments/${paymentId}/complement/`,
        data: null,
        token,
        showNotification: true,
      };
      const result = await fetchApi(config);
  
      if (result) {
        if (result.statusCode === 200) {
          // setReloadBills(true);
        }
      }

      if (file.response?.status === 'success') {
        return false;
      } else {
        const index = paymentComplementFile.indexOf(file);
        const newFileListImage = paymentComplementFile.slice();
        newFileListImage.splice(index, 1);
        setPaymentComplementFile(newFileListImage);
        return true;
      }

    },
    beforeUpload: (file) => {
      return false;
    },

    onChange: extraImagesChange,
    //type === 'watch'

    showUploadList: {
      showDownloadIcon:
        props.type === 'watch' &&
        [ROL_ADMIN, ROL_LAYOUT, ROL_SUPPLIER, ROL_FINANCIAL].some((r) =>
          person.roles.includes(r)
        ),

      showRemoveIcon:
        props.type !== 'watch' &&
        [ROL_SUPPLIER].some((r) =>
          person.roles.includes(r)
        ),
    },

    onDownload: async (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url:
          typeInvoice === 'ap'
            ? `Payments/${props.paymentId}/DownloadFile/${data.id}`
            : `ARPayments/${props.paymentId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    onPreview: (data) => {
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      const config = {
        method: METHOD_GET,
        url:
          typeInvoice === 'ap'
            ? `Payments/${props.paymentId}/DownloadFile/${data.id}`
            : `ARPayments/${props.paymentId}/DownloadFile/${data.id}`,
        data: null,
        token,
        showNotificationError: false,
      };
      fetchBlob(config)
        .then((response) => {
          let url = window.URL.createObjectURL(response);
          a.href = url;
          a.download = data.name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  };

  const uploadExtraImageServe = () => {
    setLoadingImage(true);
    setLoadingForm(true);
    if (paymentComplementFile.length > 0) {
      let imgs = [];
      paymentComplementFile.forEach((img) => {
        if (img.status === 'done') {
          imgs.push({
            extension: img.name.split('.').pop(),
            base64Promise: '',
            stringFileContent: img.stringFileContent,
            fileName: img.name,
            contentType: img.type,
          });
        } else {
          imgs.push({
            extension: img.originFileObj.name.split('.').pop(),
            base64Promise: toBase64(img.originFileObj),
            stringFileContent: '',
            fileName: img.name,
            contentType: img.type,
          });
        }
      });

      Promise.all(imgs.map((img) => img.base64Promise)).then((values) => {
        values.forEach((value, index) => {
          if (value) {
            imgs[index].stringFileContent = value.split(',')[1];
          }
        });
      });
      const data = {
        documents: imgs,
      };
      setLoadingImage(false);
      setLoadingForm(false);
      return data;
    }
  };

  useEffect(() => {
    //edit or watch
    if (paymentId) {
      setLoadingForm(true);
      const config = {
        method: METHOD_GET,
        url: `${API_URL_PAYMENTS}/${paymentId}/Complement`,
        data: null,
        token,
      };
      fetchApi(config)
        .then((response) => {
          if (response.statusCode === 200) {
            let res = response.result
            setPaymentComplementFile(
             [{
                  uid: 0,
                  name: res.fileName,
                  extension: res.extension,
                  stringFileContent: res.stringFileContent,
                  status: 'done',
                  url: res.fileLocation,
                  id: res.id,
                  thumbUrl: res.fileLocation,
                }]
            );
          }
          setLoadingForm(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingForm(false);
        });
    }
  }, [paymentId, token, invoicePaymentForm]);

  const handleSavePaymentComplement = (values) => {
    var dataImg = uploadExtraImageServe();
    if (paymentComplementFile.length === 0) {
      notification['error']({
        message: 'Debes agregar un documento',
      });
      setLoadingImage(false);
      setLoadingForm(false);
    } else {
      confirm({
        title: 'Guardar complemento de pago' ,
        icon: <ExclamationCircleOutlined />,
        content: '¿Estás seguro que deseas guardar complemento de pago?',
        okText: 'Si',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          // return;
          addPaymentComplement(values, dataImg);
        },
        onCancel() {},
      });
    }
  };
  const addPaymentComplement = (values, dataImg) => {
    setLoadingForm(true);
    values['PaymentFiles'] = dataImg.documents;
    if (paymentId) {
      values['paymentId'] = paymentId;
      values['APInvoiceId'] = invoiceId;
      values['Complement'] = dataImg.documents[0];
    }
    for (let i = 0; i < values.PaymentFiles.length; i++) {
      const e = values.PaymentFiles[i];
      if (e.extension === '') {
        notification['error']({
          message: `Error, el archivo ${e.fileName} esta dañado.`,
        });
        setLoadingImage(false);
        setLoadingForm(false);
        return;
      }
    }
    const config = {
      method: METHOD_POST,
      url:`Payments/${paymentId}/complement`,
      data: values,
      token,
      showNotification: true,
    };
    fetchApi(config)
      .then((response) => {
        setLoadingForm(false);
        setModalIsVisible(false);
        setReloadPayments(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div>
      <Form
        name="add-payment"
        layout="vertical"
        form={invoicePaymentForm}
        onFinish={handleSavePaymentComplement}
      >
        <Spin spinning={loadingForm} tip="Cargando...">
        {paymentComplementFile.length === 0 && [ROL_FINANCIAL, ROL_ADMIN, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)) && (
                    <p>No hay archivo complemento de pago</p>
            )}
          <Row>
           
            <Col>
              <Form.Item>
                <Upload
                  {...propsUpload}
                  // accept="image/png,image/jpg"
                  multiple
                  // listType="picture"
                  fileList={paymentComplementFile}
                  defaultFileList={paymentComplementFile}
                >
                  {[ROL_SUPPLIER].some((r) =>
                    person.roles.includes(r)
                  ) && (
                    <Button
                      style={{ width: 250, marginLeft: 0 }}
                      className="btn-download"
                      icon={<UploadOutlined />}
                      disabled={type === 'watch' || paymentComplementFile.length > 0}
                    >
                      Complemento de pago
                    </Button>
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Spin>

        <Row justify="end">
          <Col>
            <Form.Item>
              <Button
                className="btn-enviar"
                block
                disabled={type === 'watch' ||
                [ROL_FINANCIAL, ROL_ADMIN, ROL_LAYOUT].some((r) =>
                  person.roles.includes(r)
                )}
                htmlType="submit"
                icon={<FileAddOutlined />}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PaymentComplement;
