import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu, Row, Space, Typography, notification, Col } from 'antd';
import {
  HomeOutlined,
  PieChartOutlined,
  PhoneOutlined,
  MailOutlined,
  SnippetsOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
  SyncOutlined,
  FundOutlined,
  ToolOutlined,
  RiseOutlined,
  BranchesOutlined,
  BarChartOutlined,
  DollarCircleOutlined,
  CheckCircleOutlined,
  PauseCircleOutlined,
  LineChartOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { getContactBySectionApi } from '../../../api/sections';
import { useLocation } from 'react-router-dom';
import { getAccessTokenApi, logout } from '../../../api/auth';

import './MenuSider.scss';
import {
  ROL_ADMIN,
  ROL_MANAGER,
  ROL_OPERACIONES,
  ROL_SUPPLIER,
  ROL_FINANCIAL,
  ROL_CUSTOMER,
  ROL_PRICING,
  ROL_VENTAS,
  ROL_LAYOUT,
} from '../../../utils/constants';
import { formatPhoneNumber } from '../../../utils/general';
import { getPortalTypeApi } from '../../../api/user';
import useUser from '../../../hooks/useUser';

const MenuSider = ({ menuCollapsed, roles = [] }) => {
  const { Sider } = Layout;
  const { Paragraph } = Typography;
  const token = getAccessTokenApi();
  const portalType = getPortalTypeApi();
  const location = useLocation();
  const [nameContact, setNameContact] = useState('');
  const [phoneContact, setPhoneContact] = useState('');
  const [emailContact, setEmailContact] = useState('');
  const { SubMenu } = Menu;
  const { person } = useUser();

  useEffect(() => {
    const role = 'Financial';
    getContactBySectionApi(token, role).then((response) => {
      if (response) {
        if (response.statusCode === 401) {
          notification['error']({
            message: 'Usuario no autorizado',
          });
          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1500);
          return;
        }
        if (response.statusCode === 200) {
          setNameContact(response.result.name);
          setPhoneContact(response.result.phoneNumber);
          setEmailContact(response.result.email);
        }
      }
    });
  }, [token]);

  // let selectedKey = location.pathname;
  // let splitted = location.pathname.split('/');

  // if (splitted.length > 2) {
  //     selectedKey = '/' + splitted[1];
  // }
  return (
    <Sider
      className="admin-sider"
      collapsed={menuCollapsed}
      collapsible
      breakpoint="md"
      collapsedWidth="0"
      width={220}
    >
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={location.pathname}
        // style={{ height: '100%' }}
      >
        {[
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_SUPPLIER,
          ROL_FINANCIAL,
          ROL_CUSTOMER,
          ROL_ADMIN,
          ROL_PRICING,
          ROL_VENTAS,
          ROL_LAYOUT,
        ].some((r) => roles.includes(r)) && (
          <Menu.Item key="/">
            <Link to="/">
              <HomeOutlined />
              <span className="nav-text">Inicio</span>
            </Link>
          </Menu.Item>
        )}
        {/* <SubMenu key="sub1" icon={<UserOutlined />} title="Facturación">
                    <Menu.Item key="5">Option 5</Menu.Item>
                    <Menu.Item key="6">Option 6</Menu.Item>
                    <Menu.Item key="7">Option 7</Menu.Item>
                    <Menu.Item key="8">Option 8</Menu.Item>
                </SubMenu> */}
        {[ROL_VENTAS, ROL_MANAGER, ROL_FINANCIAL, ROL_LAYOUT].some((r) =>
          roles.includes(r)
        ) && (
          <SubMenu key="venta" icon={<RiseOutlined />} title="Ventas">
            <Menu.Item key="/cotizacion">
              <Link to="/cotizacion">
                <SnippetsOutlined />
                <span className="nav-text">Cotización</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="cliente">Clientes</Menu.Item> */}
          </SubMenu>
        )}

        {[ROL_MANAGER, ROL_PRICING, ROL_LAYOUT].some((r) =>
          roles.includes(r)
        ) && (
          <SubMenu key="pricing" icon={<DollarCircleOutlined />} title="Tarifa">
            <Menu.Item key="/pricing/tarifa">
              <Link to="/pricing/tarifa">
                <CheckCircleOutlined />
                <span className="nav-text">Tarifa</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/pricing/rfq">
              <Link to="/pricing/rfq">
                <PauseCircleOutlined />
                <span className="nav-text">RFQ</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}

        {[
          ROL_MANAGER,
          ROL_SUPPLIER,
          ROL_FINANCIAL,
          ROL_PRICING,
          ROL_LAYOUT,
        ].some((r) => roles.includes(r)) && (
          <Menu.Item key="/trafico" hidden={true}>
            <Link to="/trafico">
              <BranchesOutlined />
              <span className="nav-text">Tráfico</span>
            </Link>
          </Menu.Item>
        )}

        {[ROL_CUSTOMER].some((r) => roles.includes(r)) && (
          <>
            {portalType === 'Clientes' && (
              <Menu.Item key="/estado">
                <Link to="/estado/cuentas">
                  <BarChartOutlined />
                  <span className="nav-text">Estado de cuenta</span>
                </Link>
              </Menu.Item>
            )}
          </>
        )}

        {[
          ROL_MANAGER,
          ROL_FINANCIAL,
          // ROL_SUPPLIER,
          // ROL_CUSTOMER,
          ROL_LAYOUT,
        ].some((r) => roles.includes(r)) && (
          <SubMenu
            key="/facturacion"
            icon={<BarChartOutlined />}
            title="Finanzas"
            // hidden={true}
          >
            {/* {(portalType === 'Clientes' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/facturacion/clientes">
                <Link to="/facturacion/clientes">
                  <LineChartOutlined />
                  <span className="nav-text">Facturación cliente</span>
                </Link>
              </Menu.Item>
            )} */}

            {(portalType === 'Proveedores' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/cxp/proveedor">
                <Link to="/cxp/proveedor">
                  <DollarOutlined />
                  <span className="nav-text">CxP</span>
                </Link>
              </Menu.Item>
            )}
            {(portalType === 'Proveedores' ||
              portalType === 'Clientes' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/cxc/cliente">
                <Link to="/cxc/cliente">
                  <DollarOutlined />
                  <span className="nav-text">CxC</span>
                </Link>
              </Menu.Item>
            )}
            {(portalType === 'Clientes' ||
              portalType === 'Proveedores' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/rentabilidad">
                <Link to="/rentabilidad">
                  <RiseOutlined />
                  <span className="nav-text">Rentabilidad</span>
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )}
        {[ROL_VENTAS,ROL_PRICING,ROL_ADMIN, ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
          roles.includes(r)
        ) && (
          <SubMenu key="analisis" icon={<LineChartOutlined />} title="Análisis">
          {[ROL_VENTAS,ROL_ADMIN, ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
          roles.includes(r)
        ) && (
            <Menu.Item key="/analisis">
              <Link to="/analisis/cotizacion">
                <SnippetsOutlined />
                <span className="nav-text">Cotizaciones</span>
              </Link>
            </Menu.Item>
             )}
            <Menu.Item key="/ascenso-descenso-proveedores">
              <Link to="/analisis/ascenso-descenso/proveedores">
              <LineChartOutlined />
                <span className="nav-text">Proveedores</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/ascenso-descenso-clientes">
              <Link to="/analisis/ascenso-descenso/clientes">
              <FundOutlined />
                <span className="nav-text">Clientes</span>
              </Link>
            </Menu.Item>
          </SubMenu>
       )}
       
        {[
          ROL_MANAGER,
          ROL_FINANCIAL,
          ROL_OPERACIONES,
          ROL_VENTAS,
          ROL_LAYOUT,
        ].some((r) => roles?.includes(r)) && (
          <SubMenu key="Tráfico" icon={<SyncOutlined />} title="Tráfico">
          <Menu.Item key="/operaciones">
            <Link to="/operaciones">
              <SyncOutlined />
              <span className="nav-text">Tráfico</span>
            </Link>
          </Menu.Item>
          {[
          ROL_OPERACIONES,
          ROL_VENTAS,
          ROL_LAYOUT,
          ROL_PRICING
        ].some((r) => roles?.includes(r)) && (
          <Menu.Item key="/unidades-disponibles">
            <Link to="/unidades-disponibles">
            <CheckCircleOutlined />
              <span className="nav-text">Unidades disponibles</span>
            </Link>
          </Menu.Item>
          )}
          </SubMenu>
        )}
         {[
          ROL_OPERACIONES,
          ROL_VENTAS,
          ROL_LAYOUT,
          ROL_PRICING,
          ROL_ADMIN,
        ].some((r) => roles?.includes(r)) && (
          <Menu.Item key="/llamadas">
            <Link to="/llamadas">
            <PhoneOutlined />
              <span className="nav-text">Llamadas</span>
            </Link>
          </Menu.Item>
        )}
        {[ROL_ADMIN, ROL_MANAGER, ROL_FINANCIAL, ROL_PRICING, ROL_LAYOUT].some(
          (r) => roles?.includes(r)
        ) && (
          <Menu.Item key="/proveedores">
            <Link to="/proveedores">
              <UserOutlined />
              <span className="nav-text">Proveedores</span>
            </Link>
          </Menu.Item>
        )}
        {[
          ROL_ADMIN,
          ROL_MANAGER,
          ROL_FINANCIAL,
          ROL_OPERACIONES,
          ROL_VENTAS,
          ROL_LAYOUT,
        ].some((r) => roles?.includes(r)) && (
          <Menu.Item key="/clientes">
            <Link to="/clientes">
              <UserOutlined />
              <span className="nav-text">Clientes</span>
            </Link>
          </Menu.Item>
        )}

        {[
          // ROL_MANAGER,
          // ROL_OPERACIONES,
          // ROL_FINANCIAL,
          ROL_SUPPLIER,
          ROL_CUSTOMER,
          // ROL_LAYOUT,
        ].some((r) => roles.includes(r)) && (
          <Menu.Item key="/facturacion">
            <Link to="/facturacion">
              <PieChartOutlined />
              <span className="nav-text">Finanzas</span>
            </Link>
          </Menu.Item>
        )}
        {([ROL_SUPPLIER].some((r) => roles.includes(r)) && person.administrativeSupplier === false) && (
          <Menu.Item key="/proveedor/operaciones">
            <Link to="/proveedor/operaciones">
              <UnorderedListOutlined />
              <span className="nav-text">Operaciones</span>
            </Link>
          </Menu.Item>
        )}
        {[ROL_SUPPLIER].some((r) => roles.includes(r)) && (
          <Menu.Item key="/proveedor/cxp/ordenes/proveedores">
            <Link to="/cxp/ordenes/proveedores">
              <DollarOutlined />
              <span className="nav-text">Ordenes de compra</span>
            </Link>
          </Menu.Item>
        )}
        {/* {[
          ROL_MANAGER,
          // ROL_OPERACIONES,
          ROL_SUPPLIER,
          ROL_FINANCIAL,
        ].some((r) => roles.includes(r)) && (
          <Menu.Item key="/pagos" hidden={true}>
            <Link to="/pagos">
              <SnippetsOutlined />
              <span className="nav-text">Complemento de Pago</span>
            </Link>
          </Menu.Item>
        )} */}
        {[ROL_MANAGER, ROL_OPERACIONES, ROL_LAYOUT].some((r) =>
          roles?.includes(r)
        ) && (
          <Menu.Item key="/operaciones/facturas" hidden={true}>
            {/* <Menu.Item key='/operaciones' hidden={true}>  CAMBIAR NOMBRE EN EL LINK */}
            <Link to="/operaciones/facturas">
              <SyncOutlined />
              <span className="nav-text">Operaciones</span>
            </Link>
          </Menu.Item>
        )}
        {/* {[
          ROL_MANAGER,
          ROL_OPERACIONES,
          ROL_FINANCIAL,
          // ROL_SUPPLIER,
          // ROL_CUSTOMER,
        ].some((r) => roles.includes(r)) && (
          <SubMenu
            key="/aclaraciones"
            icon={<FileSearchOutlined />}
            title="Aclaraciones"
            hidden={true}
          >
            {(portalType === 'Clientes' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/aclaraciones/clientes">
                <Link to="/aclaraciones/clientes">
                  <span className="nav-text">A. Cliente</span>
                </Link>
              </Menu.Item>
            )}
            {(portalType === 'Proveedores' ||
              portalType === 'Clientes/Proveedores') && (
              <Menu.Item key="/aclaraciones/proveedores">
                <Link to="/aclaraciones/proveedores">
                  <span className="nav-text">A. Proveedores</span>
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        )} */}

        {/* {[
          // ROL_MANAGER,
          // ROL_OPERACIONES,
          // ROL_FINANCIAL,
          ROL_SUPPLIER,
          ROL_CUSTOMER,
        ].some((r) => roles.includes(r)) && (
          <Menu.Item key="/aclaraciones">
            <Link to="/aclaraciones">
              <FileSearchOutlined />
              <span className="nav-text">Aclaraciones</span>
            </Link>
          </Menu.Item>
        )} */}

        {roles.includes(ROL_ADMIN) && (
          <Menu.Item key="/configuracion">
            <Link to="/configuracion">
              <SettingOutlined />
              <span className="nav-text">Configuración</span>
            </Link>
          </Menu.Item>
        )}
        {[ROL_ADMIN, ROL_LAYOUT].some((r) => roles.includes(r)) && (
          <Menu.Item key="/catalog">
            <Link to="/catalog">
              <UnorderedListOutlined />
              <span className="nav-text">Catálogos</span>
            </Link>
          </Menu.Item>
        )}
        {[ROL_ADMIN, ROL_LAYOUT].some((r) => roles.includes(r)) && (
          <Menu.Item key="/administracion">
            <Link to="/administracion">
              <ToolOutlined />
              <span className="nav-text">Administración</span>
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="">
          <span className="nav-text"></span>
        </Menu.Item>
      </Menu>
      {[ROL_SUPPLIER].some((r) => roles.includes(r)) && (
        <>
          {(location.pathname === '/facturacion' ||
            location.pathname === '/pagos') && (
            <div className="menu-extra">
              {phoneContact !== '' && (
                <div className="menu-info">
                  <Row style={{ justifyContent: 'center' }}>
                    <Space size={5} align="baseline">
                      <PhoneOutlined />
                      <Paragraph>
                        Tel. {''}
                        {formatPhoneNumber(phoneContact)}
                      </Paragraph>
                    </Space>
                  </Row>
                </div>
              )}
              <div className="menu-info">
                {nameContact !== '' && (
                  <Col style={{}}>
                    <Row style={{ justifyContent: 'center' }}>
                      <MailOutlined style={{ marginTop: 3 }} />
                      <Paragraph style={{ marginLeft: 5 }}>Asesor</Paragraph>
                    </Row>
                    <Col>
                      <Paragraph>{nameContact}</Paragraph>
                    </Col>
                  </Col>
                )}
                <Row style={{ justifyContent: 'center' }}>
                  <div style={{ width: 190 }}>
                    <Paragraph>{emailContact}</Paragraph>
                  </div>
                </Row>
              </div>
            </div>
          )}
        </>
      )}
    </Sider>
  );
};

export default withRouter(MenuSider);
