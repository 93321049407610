import React, { useState, useEffect } from 'react';
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Input,
  Space,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  FileExcelFilled,
  FilePdfFilled,
  SearchOutlined,
  FilePdfOutlined,
  FileOutlined,
  FileMarkdownOutlined,
  DollarCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { formatMoney } from '../../../../utils/general';
import Highlighter from 'react-highlight-words';
import FileForm from '../FileForm/FileForm';
import Modal from '../../../General/Modal';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import BalanceForm from '../BalanceForm';
import {
  METHOD_DELETE,
  ROL_FINANCIAL,
  ROL_MANAGER,
  ROL_SUPPLIER,
} from '../../../../utils/constants';
import useUser from '../../../../hooks/useUser';
import useStatusFilter from '../../../../hooks/useStatusFilter';
import AddDocumentsForm from '../Documents/AddDocumentsForm';
import './BillingTable.scss';
import PaymentInvoice from '../PaymentInvoiceForm/PaymentInvoice';
import confirm from 'antd/lib/modal/confirm';
import { fetchApi } from '../../../../helpers/fetch';
import { getAccessTokenApi } from '../../../../api/auth';
import { getFilteredDataFromLocalStorage, saveFilteredDataToLocalStorage } from '../../../../helpers/saveFilterData';

export default function BillingTable({
  bills,
  reloadBills,
  dateRange,
  keysSelected,
  setKeysSelected,
  setReloadBills,
}) {
  const [billsData, setBillsData] = useState(bills);
  const [filteredInfo, setFilteredInfo] = useState({});
  // const [sortedInfo, setSortedInfo] = useState({});
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [titleModal, setTitleModal] = useState(false);
  const [contextModal, setContextModal] = useState(null);
  // const [excelLoading, setExcelLoading] = useState(false);
  const { person } = useUser();
  let history = useHistory();
  const [columnsType, setCollumnsType] = useState('');
  const [billsExport, setbillsExport] = useState(bills);
  const { statusFilter, reset } = useStatusFilter();
  // const [fisrtTime, setFisrtTime] = useState(true);
  const token = getAccessTokenApi();
  const [startDate, setStartDate] = useState({
    documentDate: '',
    issueDate: '',
    programDate: '',
  });
  const [endDate, setEndDate] = useState({
    documentDate: '',
    issueDate: '',
    programDate: '',
  });
  const x = React.createRef();
  useEffect(() => {
    setbillsExport(bills);
  }, [bills]);
  useEffect(() => {
    if (statusFilter.type === '') {
      setFilteredInfo({});
    }
  }, [dateRange, statusFilter]);
  useEffect(() => {
    //filtar dependiendo el estatus de los amount
    const statusReceive = statusFilter.type;
    if (statusReceive !== '') {
      // console.log(statusFilter);
      // const billsFilters = bills.filter(
      //     (bill) => bill.status === statusReceive
      // );
      let billsFilters;
      if (statusReceive === 'Total') {
        billsFilters = bills.filter(
          (bill) =>
            bill.invoiceStatus === 'Vigente' || bill.invoiceStatus === 'Vencido'
        );
      } else {
        billsFilters = bills.filter(
          (bill) => bill.invoiceStatus === statusReceive
        );
      }

      const filterEntrance = {
        documentType: null,
        invoiceSerieType: null,
        invoiceNum: null,
        currencyId: null,
        hasOpenNote: null,
        status:
          statusReceive === 'Total' ? ['Vigente', 'Vencido'] : [statusReceive],
      };
      const pagination = {
        current: 1,
        pageSize: 10,
      };

      const extra = {
        currentDataSource: billsFilters,
        action: 'filter',
      };

      handleChange(pagination, filterEntrance, {}, extra);
      setTimeout(() => {
        reset();
      }, 3000);
      // setFisrtTime(false);
    }
  }, []);
  useEffect(() => {
    const datosObtenidos = getFilteredDataFromLocalStorage('billing');
    if (datosObtenidos !== null) {
      setFilteredInfo(datosObtenidos.filters);
      setStartDate(datosObtenidos.dateFilters.startDates)
      setEndDate(datosObtenidos.dateFilters.endDates)
    }
  },[])
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            // this.searchInput = node;
          }}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Col>
          <DatePicker
            ref={x}
            id={'startDate' + dataIndex}
            placeholder={'Fecha inicial'}
            style={{ marginRight: '10px' }}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              startDate[dataIndex] !== ''
                ? moment.unix(startDate[dataIndex])
                : null
            }
            onChange={(e) => {
              // setStartDate(moment(e).unix());
              startDate[dataIndex] = moment(e).startOf('day').unix();
              setSelectedKeys(
                billsData
                  .filter(
                    (op) =>
                      moment(e)
                        .hours(0)
                        .minutes(0)
                        .seconds(0)
                        .milliseconds(0)
                        .unix() <= op[dataIndex]
                  )
                  .map((op) => op.key)
              );

              // setSelectedKeys(e !== null ? [e.format('DD/MM/YYYY')] : [])
            }}
          />
          <DatePicker
            placeholder={'Fecha final'}
            format="DD/MM/YYYY"
            allowClear={false}
            value={
              endDate[dataIndex] !== '' ? moment.unix(endDate[dataIndex]) : null
            }
            onChange={(e) => {
              // setEndDate(moment(e).unix());
              endDate[dataIndex] = moment(e).endOf('day').unix();
              setSelectedKeys(
                billsData
                  .filter(
                    (op) =>
                      op[dataIndex] <=
                      moment(e)
                        .hours(23)
                        .minutes(59)
                        .seconds(59)
                        .milliseconds(0)
                        .unix()
                  )
                  .map((op) => op.key)
              );
            }}
          />
        </Col>
        <Row justify="end" gutter={16}>
          <Col>
            <Button
            disabled={endDate[dataIndex] === ''}
              type="primary"
              onClick={() => {
                handleSearchDate(selectedKeys, confirm);
                setSelectedKeys(
                  billsData
                    .filter(
                      (d) =>
                        startDate[dataIndex] <= d[dataIndex] &&
                        d[dataIndex] <= endDate[dataIndex]
                    )
                    .map((d) => d.key)
                );
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                handleReset(clearFilters, selectedKeys, confirm, dataIndex);
                handleSearchDate(selectedKeys, confirm, dataIndex);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Col>
        </Row>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      startDate[dataIndex] <= record[dataIndex] &&
      record[dataIndex] <= endDate[dataIndex],
    filteredValue: filteredInfo[dataIndex] || null,
    onFilterDropdownVisibleChange: (visible) => {},
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? moment.unix(text).format('DD/MM/YYYY') : ''}
        />
      ) : text !== 0 && text !== null ? (
        moment.unix(text).format('DD/MM/YYYY')
      ) : null,
  });
  const columnsSupplier = [
    {
      title: 'Id. operación',
      dataIndex: 'operationIdStr',
      key: 'operationIdStr',
      ...getColumnSearchProps('operationIdStr'),
    },
    // {
    //   title: 'Proveedor',
    //   dataIndex: 'supplierName',
    //   key: 'supplierName',
    //   ...getColumnSearchProps('supplierName'),
    // },
    {
      title: 'Fecha recepción',
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'center',
      width: 120,
      render: (issueDate) => <>{moment.unix(issueDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('issueDate'),
    },
    {
      title: 'Fecha documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      align: 'center',
      width: 120,
      render: (documentDate) => (
        <>{moment.unix(documentDate).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('documentDate'),
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Ingreso', value: 'I' },
        { text: 'Egreso', value: 'E' },
      ],
      filteredValue: filteredInfo.documentType || null,
      onFilter: (value, record) => record.documentType.includes(value),

      render: (documentType) =>
        `${documentType === 'I' ? 'Ingreso' : 'Egreso'}`,
      ellipsis: false,
    },
    {
      title: 'Serie',
      dataIndex: 'invoiceSerieType',
      key: 'invoiceSerieType',
      align: 'center',
      ...getColumnSearchProps('invoiceSerieType'),
      render: (invoiceSerieType) => `${invoiceSerieType}`,
    },
    {
      title: 'Folio',
      dataIndex: 'invoiceNum',
      align: 'center',
      width: 100,
      key: 'invoiceNum',
      ...getColumnSearchProps('invoiceNum'),
      render: (folio, invoiceSerie) => (
        <Tag
          style={{ cursor: 'pointer' }}
          onClick={() => showModal(`Folio`, invoiceSerie)}
        >
          {folio}
        </Tag>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 200,
      ...getColumnSearchProps('description'),
      render: (description) => (
        <>{
        description?.length > 20 && (
        <Tooltip title={description}>
          <p>{description?.slice(0,20) + '...'}</p>
          </Tooltip>
        )}
        {description?.length <= 20 && (
          <p>{description}</p>
        )}
      </>
      ),
    },
    // {
    //   title: 'Orden de compra',
    //   dataIndex: 'purchaseOrder',
    //   key: 'purchaseOrder',
    //   align: 'center',
    //   width: 100,

    //   ...getColumnSearchProps('purchaseOrder'),
    //   render: (purchaseOrder, bill) => `${purchaseOrder ? purchaseOrder : '-'}`,
    // },
    {
      title: 'Importe',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      render: (total) => `$${formatMoney(total)}`,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      align: 'center',
      key: 'totbalanceal',
      render: (balance) => `$${formatMoney(balance)}`,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyId',
      key: 'currencyId',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currencyId || null,
      onFilter: (value, record) => record.currencyId.includes(value),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        { text: 'Vigente', value: 'VIGENTE' },
        { text: 'Pagado', value: 'PAGADO' },
        { text: 'Vencido', value: 'VENCIDO' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'VIGENTE'
              ? 'orange'
              : status === 'PAGADO'
              ? 'green'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Programación de pago',
      dataIndex: 'programDate',
      key: 'programDate',
      align: 'center',
      width: 100,
      render: (programDate) => (
        <>
          {programDate !== null
            ? moment.unix(programDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('programDate'),
    },
    {
      title: 'Término de pago',
      dataIndex: 'paymentTerms',
      key: 'paymentTerms',
      align: 'center',
      width: 100,
      ...getColumnSearchProps('paymentTerms'),
    },
    {
      title: 'Creado por:',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      width: 100,
      render: (createdBy) => `${createdBy ? createdBy.toUpperCase() : ''}`,
    },
    {
      title: 'Archivos de facturas',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 120,
      render: (id, invoice) => (
        <>
          <Tooltip title="Xml">
            <Tag color={'green'}>
              <FileMarkdownOutlined onClick={() => showModal('xml', id)} />
            </Tag>
          </Tooltip>

          <Tooltip title="PDF">
            <Tag color={'red'}>
              <FilePdfOutlined onClick={() => showModal('pdf', id)} />
            </Tag>
          </Tooltip>
          {invoice.hasAcuse !== false && (
            <Tooltip title="Evidencia">
              <Tag color={'orange'}>
                <FileOutlined onClick={() => showModal('cfdi', id)} />
              </Tag>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Pago de factura',
      dataIndex: 'description',
      align: 'center',
      key: '3',
      width: 140,
      render: (statusText, record) => (
        <>
          <Tooltip title={'Pagos'}>
            <Button
              type="primary"
              disabled={record.documentType === 'E'}
              size="small"
              style={{
                background: ` ${
                  record.documentType !== 'E' ? '#f6ffed' : '#e6e3e3'
                } `,
                color: ` ${
                  record.documentType !== 'E' ? '#389e0d' : '#a1a1a1'
                } `,
                borderColor: ` ${
                  record.documentType !== 'E' ? '#b7eb8f' : '#a1a1a1'
                } `,
              }}
              icon={<DollarCircleOutlined />}
              onClick={() =>
                history.push(
                  `/facturacion/pagos/ap/${record.id}/${record.invoiceNum}`
                )
              }
            ></Button>
            
          </Tooltip>
          {record.complementRequired && (
              <Tooltip title="Agregar complemento de pago">
               <Button
               style={{marginLeft:10}}
                size="small"
                type="danger"
                icon={<ExclamationCircleOutlined />}
               >
                 
               </Button>
               </Tooltip>
            )}
        </>
      ),
    },

    // {
    //   title: 'Evidencia',
    //   dataIndex: 'description',
    //   key: '3',
    //   width: 120,
    //   render: (statusText, record) => (
    //     <>
    //       <Tooltip title={'Agregar evidencia'}>
    //         <Button
    //           type="primary"
    //           size="small"
    //           icon={<FileAddOutlined />}
    //           onClick={() => {
    //             addDocumentModal(record.id);
    //           }}
    //         ></Button>
    //       </Tooltip>

    //       <Tooltip title={'Ver evidencias'}>
    //         <Button
    //           type="primary"
    //           ghost
    //           size="small"
    //           icon={<PaperClipOutlined />}
    //           onClick={() => {
    //             history.push(`/facturacion/detalles/documentos/${record.id}`);
    //           }}
    //           style={{ marginLeft: 15 }}
    //         ></Button>
    //       </Tooltip>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Aclaraciones',
    //   key: 'hasOpenNote',
    //   dataIndex: 'hasOpenNote',
    //   align: 'center',
    //   width: 100,
    //   filters: [
    //     { text: 'Nuevo', value: 'new' },
    //     { text: 'Abierto', value: 'open' },
    //     { text: 'Cerrado', value: 'close' },
    //   ],
    //   filteredValue: filteredInfo.hasOpenNote || null,
    //   onFilter: (value, record) => {
    //     //cerrada : hasOpenNote === false && noteid !== null
    //     if (value === 'new') {
    //       return (
    //         (!record.hasOpenNote && record.noteId === 0) ||
    //         (!record.hasOpenNote && record.noteId !== 0)
    //       );
    //     }
    //     if (value === 'open') {
    //       return record.hasOpenNote && record.noteId != null;
    //     }
    //     if (value === 'close') {
    //       return !record.hasOpenNote && record.noteId != null;
    //     }
    //   },
    //   render: (hasOpenNote, invoice) => (
    //     <>
    //       {invoice.noteId !== null && (
    //         <Tooltip
    //           placement="topLeft"
    //           title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
    //         >
    //           <Tag
    //             onClick={() =>
    //               history.push(`/aclaraciones/detail/${invoice.noteId}`)
    //             }
    //             color={hasOpenNote ? 'green' : 'gray'}
    //           >
    //             {invoice.noteId}
    //           </Tag>
    //         </Tooltip>
    //       )}
    //       {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER].some((r) =>
    //         person.roles.includes(r)
    //       ) &&
    //         !hasOpenNote && (
    //           <Tooltip placement="topLeft" title={'Nueva'}>
    //             <Tag
    //               onClick={() =>
    //                 history.push(`/aclaraciones/nueva/${invoice.id}`)
    //               }
    //               color={'green'}
    //             >
    //               <PlusCircleOutlined />
    //             </Tag>
    //           </Tooltip>
    //         )}
    //     </>
    //   ),
    // },
  ];
  const columnsSupplierAdministrative = [
    //PROVEEDOR ADMINISTRATIVO
    {
      title: 'Fecha recepción',
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'center',
      width: 120,
      render: (issueDate) => <>{moment.unix(issueDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('issueDate'),
    },
    {
      title: 'Fecha documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      align: 'center',
      width: 120,
      render: (documentDate) => (
        <>{moment.unix(documentDate).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('documentDate'),
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'documentType',
      key: 'documentType',
      width: 100,
      align: 'center',
      filters: [
        { text: 'Ingreso', value: 'I' },
        { text: 'Egreso', value: 'E' },
      ],
      filteredValue: filteredInfo.documentType || null,
      onFilter: (value, record) => record.documentType.includes(value),

      render: (documentType) =>
        `${documentType === 'I' ? 'Ingreso' : 'Egreso'}`,
      ellipsis: false,
    },
    {
      title: 'Serie',
      dataIndex: 'invoiceSerieType',
      key: 'invoiceSerieType',
      align: 'center',
      ...getColumnSearchProps('invoiceSerieType'),
      render: (invoiceSerieType) => `${invoiceSerieType}`,
    },
    {
      title: 'Folio',
      dataIndex: 'invoiceNum',
      align: 'center',
      key: 'invoiceNum',
      width: 100,
      ...getColumnSearchProps('invoiceNum'),
      render: (folio, invoiceSerie) => (
        <Tag
          style={{ cursor: 'pointer' }}
          onClick={() => showModal(`Folio`, invoiceSerie)}
        >
          {folio}
        </Tag>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 200,
      ...getColumnSearchProps('description'),
      render: (description) => (
        <>{
        description?.length > 20 && (
        <Tooltip title={description}>
          <p>{description?.slice(0,20) + '...'}</p>
          </Tooltip>
        )}
        {description?.length <= 20 && (
          <p>{description}</p>
        )}
      </>
      ),
    },
    {
      title: 'Importe',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      render: (total) => `$${formatMoney(total)}`,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      align: 'center',
      key: 'totbalanceal',
      render: (balance) => `$${formatMoney(balance)}`,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyId',
      key: 'currencyId',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currencyId || null,
      onFilter: (value, record) => record.currencyId.includes(value),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      filters: [
        { text: 'Vigente', value: 'VIGENTE' },
        { text: 'Pagado', value: 'PAGADO' },
        { text: 'Vencido', value: 'VENCIDO' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'VIGENTE'
              ? 'orange'
              : status === 'PAGADO'
              ? 'green'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Programación de pago',
      dataIndex: 'programDate',
      key: 'programDate',
      align: 'center',
      width: 100,
      render: (programDate) => (
        <>
          {programDate !== null
            ? moment.unix(programDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('programDate'),
    },
    {
      title: 'Término de pago',
      dataIndex: 'paymentTerms',
      key: 'paymentTerms',
      align: 'center',
      width: 100,
      ...getColumnSearchProps('paymentTerms'),
    },
    {
      title: 'Creado por:',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      width: 100,
      render: (createdBy) => `${createdBy ? createdBy.toUpperCase() : ''}`,
    },
    {
      title: 'Archivos de facturas',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 120,
      render: (id, invoice) => (
        <>
          <Tooltip title="Xml">
            <Tag color={'green'}>
              <FileMarkdownOutlined onClick={() => showModal('xml', id)} />
            </Tag>
          </Tooltip>

          <Tooltip title="PDF">
            <Tag color={'red'}>
              <FilePdfOutlined onClick={() => showModal('pdf', id)} />
            </Tag>
          </Tooltip>
          {invoice.hasAcuse !== false && (
            <Tooltip title="Evidencia">
              <Tag color={'orange'}>
                <FileOutlined onClick={() => showModal('cfdi', id)} />
              </Tag>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Pago de factura',
      dataIndex: 'description',
      align: 'center',
      key: '3',
      width: 140,
      render: (statusText, record) => (
        <>
          <Tooltip title={'Pagos'}>
            <Button
              type="primary"
              disabled={record.documentType === 'E'}
              size="small"
              style={{
                background: ` ${
                  record.documentType !== 'E' ? '#f6ffed' : '#e6e3e3'
                } `,
                color: ` ${
                  record.documentType !== 'E' ? '#389e0d' : '#a1a1a1'
                } `,
                borderColor: ` ${
                  record.documentType !== 'E' ? '#b7eb8f' : '#a1a1a1'
                } `,
              }}
              icon={<DollarCircleOutlined />}
              onClick={() =>
                history.push(
                  `/facturacion/pagos/ap/${record.id}/${record.invoiceNum}`
                )
              }
            ></Button>
            
          </Tooltip>
          {record.complementRequired && (
              <Tooltip title="Agregar complemento de pago">
               <Button
               style={{marginLeft:10}}
                size="small"
                type="danger"
                icon={<ExclamationCircleOutlined />}
               >
                 
               </Button>
               </Tooltip>
            )}
        </>
      ),
    },
  ];
  const columnsAll = [
    // COMO EJECUTIVO
    {
      title: 'Id. proveedor',
      dataIndex: 'supplierId',
      key: 'supplierId',
      ...getColumnSearchProps('supplierId'),
    },
    {
      title: 'Proveedor',
      dataIndex: 'supplierName',
      key: 'supplierName',
      ...getColumnSearchProps('supplierName'),
    },
    {
      title: 'Id. operación',
      dataIndex: 'operationIdStr',
      key: 'operationIdStr',
      ...getColumnSearchProps('operationIdStr'),
    },
    {
      title: 'Fecha recepción',
      dataIndex: 'issueDate',
      key: 'issueDate',
      align: 'center',
      width: 120,
      render: (issueDate) => <>{moment.unix(issueDate).format('DD/MM/YYYY')}</>,
      ...getColumnSearchDateProps('issueDate'),
    },
    {
      title: 'Fecha documento',
      dataIndex: 'documentDate',
      key: 'documentDate',
      align: 'center',
      width: 120,
      render: (documentDate) => (
        <>{moment.unix(documentDate).format('DD/MM/YYYY')}</>
      ),
      ...getColumnSearchDateProps('documentDate'),
    },
    {
      title: 'Tipo de documento',
      dataIndex: 'documentType',
      key: 'documentType',
      align: 'center',
      width: 100,
      filters: [
        { text: 'Ingreso', value: 'I' },
        { text: 'Egreso', value: 'E' },
      ],
      filteredValue: filteredInfo.documentType || null,
      onFilter: (value, record) => record.documentType.includes(value),

      render: (documentType) =>
        `${documentType === 'I' ? 'Ingreso' : 'Egreso'}`,
      ellipsis: false,
    },
    {
      title: 'Serie',
      dataIndex: 'invoiceSerieType',
      key: 'invoiceSerieType',
      align: 'center',
      ...getColumnSearchProps('invoiceSerieType'),
    },
    {
      title: 'Folio',
      dataIndex: 'invoiceNum',
      align: 'center',
      width: 100,
      key: 'invoiceNum',
      ...getColumnSearchProps('invoiceNum'),
    },
    // {
    //   title: 'Orden de compra',
    //   dataIndex: 'purchaseOrder',
    //   key: 'purchaseOrder',
    //   align: 'center',
    //   ...getColumnSearchProps('purchaseOrder'),
    //   // render: (purchaseOrder, bill) => (
    //   //     <p>{purchaseOrder ? purchaseOrder : '-'}</p>
    //   // ),
    // },
    {
      title: 'Importe',
      dataIndex: 'total',
      align: 'center',
      key: 'total',
      render: (total) => `$${formatMoney(total)}`,
    },
    {
      title: 'Saldo',
      dataIndex: 'balance',
      align: 'center',
      key: 'totbalanceal',
      render: (balance) => `$${formatMoney(balance)}`,
    },
    {
      title: 'Moneda',
      dataIndex: 'currencyId',
      key: 'currencyId',
      align: 'center',
      filters: [
        { text: 'MXN', value: 'MXN' },
        { text: 'USD', value: 'USD' },
      ],
      filteredValue: filteredInfo.currencyId || null,
      onFilter: (value, record) => record.currencyId.includes(value),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Vigente', value: 'VIGENTE' },
        { text: 'Pagado', value: 'PAGADO' },
        { text: 'Vencido', value: 'VENCIDO' },
      ],
      filteredValue: filteredInfo.status || null,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => (
        <Tag
          color={
            status === 'VIGENTE'
              ? 'orange'
              : status === 'PAGADO'
              ? 'green'
              : 'red'
          }
        >
          {status.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: 'Programación de pago',
      dataIndex: 'programDate',
      key: 'programDate',
      align: 'center',
      width: 100,
      render: (programDate) => (
        <>
          {programDate !== null
            ? moment.unix(programDate).format('DD/MM/YYYY')
            : null}
        </>
      ),
      ...getColumnSearchDateProps('programDate'),
    },
    {
      title: 'Término de pago',
      dataIndex: 'paymentTerms',
      key: 'paymentTerms',
      align: 'center',
      width: 100,
      ...getColumnSearchProps('paymentTerms'),
    },
    {
      title: 'Creado por:',
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'center',
      width: 100,
      render: (createdBy) => `${createdBy ? createdBy.toUpperCase() : ''}`,
    },
    {
      title: 'Archivos de facturas',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (id, invoice) => (
        <>
          <Tooltip title="Xml">
            <Tag color={'green'}>
              <FileMarkdownOutlined onClick={() => showModal('xml', id)} />
            </Tag>
          </Tooltip>

          <Tooltip title="PDF">
            <Tag color={'red'}>
              <FilePdfOutlined onClick={() => showModal('pdf', id)} />
            </Tag>
          </Tooltip>
          {invoice.hasAcuse !== false && (
            <Tooltip title="Evidencia">
              <Tag color={'orange'}>
                <FileOutlined onClick={() => showModal('cfdi', id)} />
              </Tag>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Pagos',
      dataIndex: 'description',
      key: '3',
      align: 'center',
      width: 100,
      render: (statusText, record) => (
        <>
          <Tooltip title={'Agregar pago'}>
            <Button
              type="primary"
              size="small"
              disabled={record.documentType === 'E'}
              style={{
                background: ` ${
                  record.documentType !== 'E' ? '#f6ffed' : '#e6e3e3'
                } `,
                color: ` ${
                  record.documentType !== 'E' ? '#389e0d' : '#a1a1a1'
                } `,
                borderColor: ` ${
                  record.documentType !== 'E' ? '#b7eb8f' : '#a1a1a1'
                } `,
              }}
              icon={<DollarCircleOutlined />}
              onClick={() =>
                history.push(
                  `/facturacion/pagos/ap/${record.id}/${record.invoiceNum}`
                )
              }
            ></Button>
          </Tooltip>
          {record.complementRequired && (
              <Tooltip title="Falta complemento de pago">
               <Button
               style={{marginLeft:10}}
                size="small"
                type="danger"
                icon={<ExclamationCircleOutlined />}
               >
                 
               </Button>
               </Tooltip>
            )}
        </>
      ),
    },
    {
      title: 'Acción',
      key: 'action',
      align: 'center',
      width: 100,
      render: (text, record) => (
        <>
          {![ROL_SUPPLIER].some((r) => person.roles.includes(r)) && (
            <>
              <Tooltip title="Eliminar">
                <Button
                  danger
                  disabled={record.status === 'PAGADO'}
                  size="small"
                  type="primary"
                  onClick={() => handleDeleteBill(record.id)}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          )}
        </>
      ),
    },
    // {
    //   title: 'Evidencia',
    //   dataIndex: 'description',
    //   key: '3',
    //   width: 120,
    //   render: (statusText, record) => (
    //     <>
    //       <Tooltip title={'Agregar evidencia'}>
    //         <Button
    //           type="primary"
    //           size="small"
    //           icon={<FileAddOutlined />}
    //           onClick={() => {
    //             addDocumentModal(record.id);
    //           }}
    //         ></Button>
    //       </Tooltip>

    //       <Tooltip title={'Ver evidencias'}>
    //         <Button
    //           type="primary"
    //           ghost
    //           size="small"
    //           icon={<PaperClipOutlined />}
    //           onClick={() => {
    //             history.push(`/facturacion/detalles/documentos/${record.id}`);
    //           }}
    //           style={{ marginLeft: 15 }}
    //         ></Button>
    //       </Tooltip>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Aclaraciones',
    //   key: 'noteId',
    //   dataIndex: 'hasOpenNote',
    //   align: 'center',
    //   width: 100,
    //   filters: [
    //     // { text: 'Nuevo', value: null },
    //     { text: 'Abierto', value: true },
    //     { text: 'Cerrado', value: false },
    //   ],
    //   filteredValue: filteredInfo.hasOpenNote || null,
    //   onFilter: (value, record) => record.hasOpenNote.includes(value),
    //   render: (hasOpenNote, invoice) => (
    //     <>
    //       {invoice.noteId !== null && (
    //         <Tooltip
    //           placement="topLeft"
    //           title={`${hasOpenNote ? 'Abierta' : 'Cerrada'}`}
    //         >
    //           <Tag
    //             onClick={() =>
    //               history.push(`/aclaraciones/detail/${invoice.noteId}`)
    //             }
    //             color={hasOpenNote ? 'green' : 'gray'}
    //           >
    //             {invoice.noteId}
    //           </Tag>
    //         </Tooltip>
    //       )}
    //       {[ROL_FINANCIAL, ROL_LAYOUT, ROL_MANAGER].some((r) =>
    //         person.roles.includes(r)
    //       ) &&
    //         !hasOpenNote && (
    //           <Tooltip placement="topLeft" title={'Nueva'}>
    //             <Tag
    //               onClick={() =>
    //                 history.push(`/aclaraciones/nueva/${invoice.id}`)
    //               }
    //               color={'green'}
    //             >
    //               <PlusCircleOutlined />
    //             </Tag>
    //           </Tooltip>
    //         )}
    //     </>
    //   ),
    // },
  ];
  const handleDeleteBill = (id) => {
    confirm({
      title: 'Eliminar factura',
      icon: <ExclamationCircleOutlined />,
      content: '¿Estás seguro que deseas eliminar la factura seleccionada?',
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteBilling(id);
      },
      onCancel() {},
    });
  };
  const deleteBilling = async (id) => {
    const config = {
      method: METHOD_DELETE,
      url: `APInvoice/${id}`,
      data: null,
      token,
      showNotification: true,
    };
    const result = await fetchApi(config);

    if (result) {
      if (result.statusCode === 200) {
        setReloadBills(true);
      }
    }
  };
  useEffect(() => {
    if([ROL_SUPPLIER].some((r) => person.roles.includes(r)) && person.administrativeSupplier === false ){
      setCollumnsType('Supplier');
    }else if([ROL_SUPPLIER].some((r) => person.roles.includes(r)) && person.administrativeSupplier === true ){
      setCollumnsType('Administrative');
    }
    // setColumns([newColumn,...columns])
  }, [person]);
  useEffect(() => {
    bills.forEach((bill) => {
      bill['invoiceSerieType'] = bill.serie;
    });

    setBillsData(bills);
  }, [bills]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearchDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText('');
    startDate[dataIndex] = '';
    endDate[dataIndex] = '';
  };
  const handleChange = (pagination, filters, sorter, extra) => {
    const dateFilters = { startDates: startDate, endDates: endDate}
    saveFilteredDataToLocalStorage('billing', pagination, sorter, filters, dateFilters);
    setFilteredInfo(filters);
    setbillsExport(extra.currentDataSource);
  };

  // const clearAll = () => {
  //   setFilteredInfo({});
  //   // setSortedInfo({});
  // };

  const showModal = (type, reference) => {
    setModalIsVisible(true);
    if (type === 'xml') {
      setTitleModal('Archivo XML');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'pdf') {
      setTitleModal('Archivo PDF');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'cfdi') {
      setTitleModal('Archivo Evidencia');
      setContextModal(
        <FileForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></FileForm>
      );
    }
    if (type === 'Folio') {
      setTitleModal('Folio');
      setContextModal(
        <BalanceForm
          setModalIsVisible={setModalIsVisible}
          type={type}
          reference={reference}
        ></BalanceForm>
      );
    } else if (type === 'PayInvoice') {
      setModalIsVisible(true);
      setTitleModal(`Factura #${reference.invoiceNum} a pagar`);
      setContextModal(
        <PaymentInvoice
          setModalIsVisible={setModalIsVisible}
          invoiceId={reference.id}
          paymentId={reference.paymentId}
        ></PaymentInvoice>
      );
    }
  };

  const generateExcel = (entries) => {
    // setExcelLoading(true);
    const filename = `Facturación-y-notasdecredito${moment().format('L')}.xlsx`;

    let fecha = moment().format('LLL');
    const ws_name = 'Hoja1';
    let wb = {
      SheetNames: [],
      Sheets: {},
    };
    let ws = {};
    let renInicial = 4;

    // var mstrTitulo = `Facturación y notas de crédito del ${dateRange.startDate} al: ${dateRange.endDate}`;
    var mstrTitulo = `Facturación y notas de crédito`;

    if ([ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))) {
      ws = {
        A1: { v: mstrTitulo, t: 's' },
        I1: { v: fecha, t: 's' },

        A3: { v: 'Id. proveedor', t: 's' },
        B3: { v: 'Proveedor', t: 's' },
        C3: { v: 'ID operación', t: 's' },
        D3: { v: 'Fecha de recepción', t: 's' },
        E3: { v: 'Fecha documento', t: 's' },
        F3: { v: 'Tipo documento', t: 's' },
        G3: { v: 'Serie', t: 's' },
        H3: { v: 'Folio', t: 's' },
        // H3: { v: 'Orden de compra', t: 's' },
        I3: { v: 'Importe', t: 's' },
        J3: { v: 'Moneda', t: 's' },
        K3: { v: 'Estatus', t: 's' },
        L3: { v: 'Programación de pago', t: 's' },
        M3: { v: 'Término de pago', t: 's' },
      };
      for (let i in entries) {
        const {
          supplierId,
          supplierName,
          operationIdStr,
          issueDate,
          documentDate,
          documentType,
          serie,
          invoiceNum,
          total,
          currencyId,
          status,
          programDate,
          paymentTerms,
        } = entries[i];
        ws['A' + renInicial] = { v: supplierId, t: 's' };
        ws['B' + renInicial] = { v: supplierName, t: 's' };
        ws['C' + renInicial] = { v: operationIdStr, t: 's' };

        ws['D' + renInicial] = {
          v: moment.unix(issueDate).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['E' + renInicial] = {
          v: moment.unix(documentDate).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['F' + renInicial] = {
          v: documentType === 'I' ? 'Ingreso' : 'Egreso',
          t: 's',
        };
        ws['G' + renInicial] = { v: serie, t: 's' };
        ws['H' + renInicial] = { v: invoiceNum, t: 's' };
        // ws['H' + renInicial] = {
        //   v: purchaseOrder ? purchaseOrder : '-',
        //   t: 's',
        // };
        ws['I' + renInicial] = {
          v: `$${formatMoney(total)}`,
          t: 's',
        };
        ws['J' + renInicial] = { v: currencyId, t: 's' };
        ws['K' + renInicial] = { v: status, t: 's' };
        ws['L' + renInicial] = {
          v:
            programDate !== null
              ? moment.unix(programDate).format('DD/MM/YYYY')
              : '',
          t: 's',
        };
        ws['M' + renInicial] = { v: paymentTerms, t: 's' };

        renInicial++;
      }
    } else {
      ws = {
        A1: { v: mstrTitulo, t: 's' },
        I1: { v: fecha, t: 's' },

        A3: { v: 'Id. operación', t: 's' },
        B3: { v: 'Proveedor', t: 's' },
        C3: { v: 'Fecha de recepción', t: 's' },
        D3: { v: 'Fecha documento', t: 's' },
        E3: { v: 'Tipo documento', t: 's' },
        F3: { v: 'Serie', t: 's' },
        G3: { v: 'Folio', t: 's' },
        // G3: { v: 'Orden de compra', t: 's' },
        H3: { v: 'Importe', t: 's' },
        I3: { v: 'Moneda', t: 's' },
        J3: { v: 'Estatus', t: 's' },
        K3: { v: 'Programación de pago', t: 's' },
        L3: { v: 'Término de pago', t: 's' },
      };
      for (let i in entries) {
        const {
          operationIdStr,
          supplierName,
          issueDate,
          documentDate,
          documentType,
          serie,
          invoiceNum,
          total,
          currencyId,
          status,
          programDate,
          paymentTerms,
        } = entries[i];
        ws['A' + renInicial] = { v: operationIdStr, t: 's' };
        ws['B' + renInicial] = { v: supplierName, t: 's' };

        ws['C' + renInicial] = {
          v: moment.unix(issueDate).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['D' + renInicial] = {
          v: moment.unix(documentDate).format('DD/MM/YYYY'),
          t: 's',
        };
        ws['E' + renInicial] = {
          v: documentType === 'I' ? 'Ingreso' : 'Egreso',
          t: 's',
        };
        ws['F' + renInicial] = { v: serie, t: 's' };
        ws['G' + renInicial] = { v: invoiceNum, t: 's' };
        // ws['G' + renInicial] = {
        //   v: purchaseOrder ? purchaseOrder : '-',
        //   t: 's',
        // };
        ws['H' + renInicial] = {
          v: `$ ${formatMoney(total)}`,
          t: 's',
        };
        ws['I' + renInicial] = { v: currencyId, t: 's' };
        ws['J' + renInicial] = { v: status, t: 's' };
        ws['K' + renInicial] = {
          v:
            programDate !== null
              ? moment.unix(programDate).format('DD/MM/YYYY')
              : '',
          t: 's',
        };
        ws['L' + renInicial] = { v: paymentTerms, t: 's' };
        renInicial++;
      }
    }

    // var wscols = [{ wch: 30 }, { wch: 40 }, { wch: 35 }, { wch: 35 }];
    // ws['!cols'] = wscols;
    ws['!ref'] = XLSX.utils.encode_range({
      s: { c: 0, r: 0 },
      e: { c: 15, r: renInicial },
    });
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    XLSX.writeFile(wb, filename, { cellStyles: true });
    // setExcelLoading(false);
  };

  const exportPdf = (bills, startDate, endDate) => {
    var mstrTitulo = `Facturación y notas de crédito`;

    var mstrPdf = `Facturación_y_notasdecredito${moment().format('L')}.pdf`;

    var registros = [];
    if ([ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))) {
      bills.forEach((element) => {
        registros.push([
          element.supplierId,
          element.supplierName,
          element.operationIdStr,
          moment.unix(element.issueDate).format('DD/MM/YYYY'),
          moment.unix(element.documentDate).format('DD/MM/YYYY'),
          element.documentType === 'I' ? 'Ingreso' : 'Egreso',
          element.serie,
          element.invoiceNum,
          '$ ' + formatMoney(element.total),
          element.currencyId,
          element.status,
          element.programDate !== null
            ? moment.unix(element.programDate).format('DD/MM/YYYY')
            : '',
          element.paymentTerms,
        ]);
      });
    } else {
      bills.forEach((element) => {
        registros.push([
          element.operationIdStr,
          element.supplierName,
          moment.unix(element.issueDate).format('DD/MM/YYYY'),
          moment.unix(element.documentDate).format('DD/MM/YYYY'),
          element.documentType === 'I' ? 'Ingreso' : 'Egreso',
          element.serie,
          element.invoiceNum,
          '$ ' + formatMoney(element.total),
          element.currencyId,
          element.status,
          element.programDate !== null
            ? moment.unix(element.programDate).format('DD/MM/YYYY')
            : '',
          element.paymentTerms,
        ]);
      });
    }
    let fecha = moment().format('LLL');
    let doc = new jsPDF('landscape');
    doc.setFontSize(12);

    let xFecha = 220;
    let yFecha = 10;

    let totalPagesExp = '{total_pages_count_string}';

    let pageContent = function (data) {
      //header
      doc.text(15, 10, mstrTitulo);
      doc.text(-10, -10, mstrTitulo);
      doc.text(fecha, xFecha, yFecha);
      // FOOTER
      let str = 'Página ' + data.pageCount;
      //Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }
      doc.setFontSize(10);
      doc.text(
        str,
        data.settings.margin.left,
        doc.internal.pageSize.height - 10
      );
    };

    if ([ROL_MANAGER, ROL_FINANCIAL].some((r) => person.roles.includes(r))) {
      doc.autoTable({
        head: [
          [
            'Id. proveedor',
            'Proveedor',
            'ID operación',
            'Fecha recepción',
            'Fecha documento',
            'Tipo de documento',
            'serie',
            'Folio',
            // 'Orden de compra',
            'Importe',
            'Moneda',
            'Estatus',
            'Programación de pago',
            'Término de pago',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
        },
        body: registros,
        columnStyles: {
          text: {
            cellWidth: 'wrap',
          },
        },
      });
    } else {
      doc.autoTable({
        head: [
          [
            'Id. operación',
            'Proveedor',
            'Fecha recepción',
            'Fecha documento',
            'Tipo de documento',
            'serie',
            'Folio',
            // 'Orden de compra',
            'Importe',
            'Moneda',
            'Estatus',
            'Programación de pago',
            'Término de pago',
          ],
        ],
        didDrawPage: pageContent,
        theme: 'grid',
        headStyles: {
          valign: 'middle',
          halign: 'center',
          fillColor: [230, 81, 0],
          textColor: [255, 255, 255],
        },
        body: registros,
        columnStyles: {
          text: {
            cellWidth: 'wrap',
          },
        },
      });
    }

    // call footer() after each doc.addPage()

    // and before doc.save() do not forget put
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    //spinner.style.display = 'none';
    doc.save(mstrPdf);
  };

  const addDocumentModal = (id) => {
    setTitleModal(`Agregar documentos - ${id}`);
    setModalIsVisible(true);
    setContextModal(
      <AddDocumentsForm
        setModalIsVisible={setModalIsVisible}
        operationId={id}
      ></AddDocumentsForm>
    );
  };

  const rowSelection = {
    selectedRowKeys: keysSelected,
    onChange: (newKeys, newRows) => {
      const final = newRows[newRows.length - 1];
      if (
        newRows.every(
          (e) =>
            e.supplierId === final.supplierId &&
            e.currencyId === final.currencyId
        )
      ) {
        setKeysSelected(newKeys);
        // setBillsSelected(newRows);
      } else {
        setKeysSelected([final.key]);
        // setBillsSelected([final]);
      }
    },
    getCheckboxProps: (record) => ({
      disabled:
        record.status !== 'VIGENTE' ||
        record.canGroupedInSO === false ||
        [ROL_MANAGER].some((r) => person.roles.includes(r)),
    }),
  };
  return (
    <>
      {/* <Button danger onClick={clearAll} style={{ marginRight: 10 }}>
                Limpiar filtros
            </Button> */}
      <Table
        // bordered
        rowSelection={columnsType === 'Supplier' || columnsType === 'Administrative'? null:  rowSelection}
        onChange={handleChange}
        columns={columnsType === 'Supplier' ? columnsSupplier : columnsType === 'Administrative' ? columnsSupplierAdministrative:  columnsAll}
        dataSource={billsData}
        scroll={{ x: 2400 }}
        // defaultPageSize={10}
        loading={reloadBills}
        // pagination={{
        //     pageSize: 10,
        // }}
      />

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            icon={<FilePdfFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => exportPdf(billsExport)}
            disabled={billsExport.length === 0}
            style={{ width: 150 }}
          >
            Descargar Pdf
          </Button>
          <Button
            icon={<FileExcelFilled style={{ fontSize: 18 }} />}
            className="btn-download"
            onClick={() => generateExcel(billsExport)}
            disabled={billsExport.length === 0}
            style={{ width: 160 }}
          >
            Descargar Excel
          </Button>
        </Col>
      </Row>
      <Modal
        title={titleModal}
        visible={modalIsVisible}
        setIsVisible={setModalIsVisible}
        width={1000}
      >
        {contextModal}
      </Modal>
    </>
  );
}
